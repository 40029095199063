<template>
	<v-dialog
		fullscreen
		:value="value"
		@input="$emit('input', $event)"
		@keydown.esc="HideImageDialog"
		@keydown.left.exact="OnLeftKeyPress"
		@keydown.right.exact="OnRightKeyPress"
		:scrollable="false"
	>
		<!-- @keydown.right.ctrl.exact="SaveAndRight" -->
		<v-card>
			<v-toolbar dense float color="" v-if="carouselCurrentIndex >= 0">
				<v-btn icon @click="HideImageDialog">
					<v-icon>fas fa-arrow-left</v-icon>
				</v-btn>

				<v-toolbar-title>{{ currentFileName }}</v-toolbar-title>
			</v-toolbar>
			<v-container>
				<v-row>
					<v-col class="pa-2" cols="12" sm="6" lg="8">
						<v-carousel
							v-bind:value="carouselCurrentIndex"
							@change="OnCarouselCurrentIndex"
							:continuous="false"
							:hide-delimiters="true"
							class=""
							prev-icon="fas fa-angle-left"
							next-icon="fas fa-angle-right"
						>
							<v-carousel-item v-for="(file, i) in Files" :key="i">
								<v-img
									class="carouselImage"
									contain
									v-if="file.Metadata && file.Metadata.PreviewFile"
									:key="file.Uri"
									:src="file.Uri + SasToken"
									:lazy-src="file.Metadata.PreviewFile + SasToken"
									><template v-slot:placeholder>
										<v-row class="fill-height ma-0" align="center" justify="center">
											<v-progress-circular
												indeterminate
												color="grey lighten-2"
											></v-progress-circular>
										</v-row>
									</template>
								</v-img>

								<template v-else>
									<v-row class="fill-height ma-0" align="center" justify="center">
										<div class="text-center grey--text">
											<v-icon size="120" color="grey">fas fa-file-image </v-icon>
											<div class="ma-3 text-h5">
												{{ file.DisplayName }}
											</div>
										</div>
									</v-row>
								</template>
							</v-carousel-item>
						</v-carousel>
					</v-col>
					<v-col cols="12" sm="6" lg="4" v-if="currentFile && currentFile.Metadata">
						<v-card class="mb-3">
							<v-card-text>
								<v-btn
									color="primary"
									block
									:loading="DetectingFaces"
									@click="DetectFaces"
								>
									<v-icon left>fas fa-draw-square</v-icon> Trova Visi</v-btn
								>
								<v-switch label="Trova tutti visi" v-model="findAllFaces"></v-switch>

								<div v-if="currentFile.Metadata.FaceIds">
									<FacePersonAddSelect
										v-for="detectedFace in JSON.parse(currentFile.Metadata.FaceIds)"
										:key="detectedFace.FaceId"
										:detectedFace="detectedFace"
										:eventId="EventId"
										:originalImage="currentFile"
										@faceAdded="OnFaceAdded"
										@ignoreFace="OnIgnoreFace"
									></FacePersonAddSelect>
								</div>
							</v-card-text>
						</v-card>
						<v-card>
							<v-card-title>Exif ImageDescription Attuale</v-card-title>
							<v-card-text>
								<h6 class="text-h6">
									<v-icon>fas fa-tag</v-icon>
									{{ currentFile.Metadata.ImageDescription || "--" }}
								</h6>
							</v-card-text>

							<v-card-title>Modifica Exif ImageDescription</v-card-title>
							<v-card-text>
								<v-text-field
									label="Nomi"
									v-model="exifImageDescription"
									@keydown.stop
								></v-text-field
							></v-card-text>
							<v-card-actions>
								<!-- Ctrl + <v-icon class="ml-2">fas fa-caret-right</v-icon> per aggiorna e
								continua							 -->
								<v-spacer></v-spacer
								><v-btn
									@click="UpdateExifDescription"
									:loading="updatingExifDescription"
									class="primary"
									>{{ $t("common.update") }}</v-btn
								>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</v-dialog>
</template>
<script>
import api from "@/services/api"
import CrudClient from "@/services/CrudClient/";
import FacePersonAddSelect from "./FacePersonAddSelect";
import { mapActions, mapGetters } from "vuex";

export default {
	name: "FaceRecoImageDialog",
	components: {
		FacePersonAddSelect,
	},
	props: {
		value: { type: Boolean, required: true },
		EventId: { type: Number, required: true },
		Files: { type: Array, required: true },
		carouselCurrentIndex: { type: Number },
		SgpEventsService: { type: Object, required: true },
	},
	data() {
		return {
			DetectingFaces: false,
			faceNames: {},
			exifImageDescription: null,
			updatingExifDescription: false,
			ignoreFaceIds: [],
			findAllFaces: false,
		};
	},
	computed: {
		...mapGetters(["SasToken"]),

		currentFile() {
			return this.Files[this.carouselCurrentIndex];
		},

		currentFileName() {
			if (this.Files[this.carouselCurrentIndex]) {
				return this.Files[this.carouselCurrentIndex].Uri.split("/").pop();
			}
			return null;
		},
	},
	watch: {
		value: async function (val) {
			this.$log.debug("watch value");
			this.$log.debug(val);
		},

		carouselCurrentIndex: async function (val) {
			this.exifImageDescription = null;
			this.ignoreFaceIds = [];
			if (this.currentFile?.Metadata?.FaceIds) {
				await this.FillTextFieldWithPersonNames();
			}
		},
	},
	created() {
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		OnRightKeyPress() {
			this.$emit("carouselRight");
		},
		OnLeftKeyPress() {
			this.$emit("carouselLeft");
		},

		async SaveAndRight() {
			await this.UpdateExifDescription();
			this.$emit("carouselRight");
		},

		OnIgnoreFace(detectedFace) {
			this.ignoreFaceIds.push(detectedFace.FaceId);

			this.FillTextFieldWithPersonNames();
		},

		async FillTextFieldWithPersonNames() {
			const faceIds = JSON.parse(this.currentFile.Metadata.FaceIds);

			this.exifImageDescription = "";

			for (let i = 0; i < faceIds.length; i++) {
				const detectedFace = faceIds[i];

				if (this.ignoreFaceIds.find((f) => f === detectedFace.FaceId)) continue;

				if (this.exifImageDescription !== "") this.exifImageDescription += ";";

				if (
					detectedFace.identifyResults.length > 0 &&
					detectedFace.identifyResults[0].length
				) {
					const name = await this.GetFacePerson(
						detectedFace.identifyResults[0][0].PersonId
					);

					this.exifImageDescription += name;
				} else {
					this.exifImageDescription += "?";
				}
			}
		},

		async GetFacePerson(personId) {
			try {
				const FacePersonService = new CrudClient("Face/Person");
				const res = await FacePersonService.GetPaged({
					limit: 1,
					skip: 0,
					fields: "Name",
					filter: `PersonGroupPersonId:${personId}`,
				});
				if (res.Data.length > 0) return res.Data[0].Name;

				return "[person not found]";
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
			}
		},

		async OnFaceAdded(blob) {
			this.DetectFaces();

			this.$emit("facesDetected", [blob]);
		},

		async DetectFaces() {
			this.DetectingFaces = true;
			try {
				const azureFunctionClient = new CrudClient("Face", api.azureFunctionApiUrl);

				const updatedItems = await azureFunctionClient.Post(
					this.EventId,
					{ ImageFilePath: this.Files[this.carouselCurrentIndex].Path },
					"IdentifyFaces" + (this.findAllFaces ? "?filterSmallerFaces=false" : ""),
					true
				);

				this.$emit("facesDetected", [updatedItems]);

				await this.FillTextFieldWithPersonNames();
			} catch (error) {
				this.snackError({ Text: "cannotDetectFaces" });
				this.$captureError(error);
			} finally {
				this.DetectingFaces = false;
			}
		},

		async UpdateExifDescription() {
			try {
				this.updatingExifDescription = true;

				const dto = {
					FileName: this.currentFile.Path,
					ImageDescription: this.exifImageDescription,
				};

				const azureFunctionClient = new CrudClient("SGP/Events", api.azureFunctionApiUrl);
				var res = await azureFunctionClient.Post(
					this.EventId,
					dto,
					"exifImageDescription",
					true
				);
				this.$emit("exifUpdated", res);
				this.snackSuccess({ Text: "Metadata aggiornato" });
			} catch (error) {
				this.snackError("cannotDetectFaces");
				this.$captureError(error);
			} finally {
				this.updatingExifDescription = false;
			}
		},

		HideImageDialog() {
			this.$emit("input", false);
		},

		OnCarouselCurrentIndex(param) {
			this.$emit("carousel-current-index-changed", param);
		},
		carouselResize() {
			this.$log.debug("carouselResize");
		},
	},
};
</script>
<i18n>
{
	"it":{
		"cannotDetectFaces": "Impossibile trovare i visi"
	}
}
</i18n>
<style lang="scss" scoped>
.fullscreenContainer {
	height: calc(100vh - 48px) !important;
	/* max-height: calc(100vh-48px); */
}

.carouselImage {
	width: 100%;
	height: 100%;
	object-fit: contain;
	display: block;
}
</style>
