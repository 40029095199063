<template>
	<v-dialog
		fullscreen
		:value="value"
		@input="$emit('input', $event)"
		@keydown.esc="HideImageDialog"
		:scrollable="false"
	>
		<v-toolbar dense float color="" v-if="carouselCurrentIndex >= 0">
			<v-btn icon @click="HideImageDialog">
				<v-icon>fas fa-arrow-left</v-icon>
			</v-btn>

			<v-toolbar-title>{{ selectedFileName }}</v-toolbar-title>

			<v-spacer></v-spacer>
			<template v-if="!showImageEditor">
				<v-btn
					v-if="
						Files[carouselCurrentIndex] && !Files[carouselCurrentIndex].IsDirectory
					"
					icon
					@click="
						Files[carouselCurrentIndex].Selected =
							!Files[carouselCurrentIndex].Selected
					"
				>
					<v-icon v-if="!Files[carouselCurrentIndex].Selected" color="">
						far fa-circle
					</v-icon>
					<v-icon v-else color="blue"> fas fa-check-circle </v-icon>
				</v-btn>

				<v-btn
					v-if="
						Files[carouselCurrentIndex] &&
						Files[carouselCurrentIndex].Metadata &&
						Files[carouselCurrentIndex].Metadata.PreviewFile
					"
					icon
					@click="ShowEditor(Files[carouselCurrentIndex])"
				>
					<v-icon>fas fa-sliders-h</v-icon>
				</v-btn>
			</template>
			<template v-else>
				<v-btn icon @click="SaveImage"> <v-icon>fas fa-save</v-icon> </v-btn>
				<v-btn icon @click="HideEditor">
					<v-icon>fas fa-times-circle</v-icon>
				</v-btn>
			</template>
		</v-toolbar>
		<v-container fill-height fluid class="black fullscreenContainer">
			<v-row align="center" justify="center">
				<v-col class="pa-0" cols="12">
					<div class="fullscreenContainer" v-if="showImageEditor">
						<PinturaEditor
							ref="pinturaEditor"
							v-bind="editorDefaults"
							:src="src + SasToken"
							@pintura:process="SaveEditedImage"
						></PinturaEditor>
					</div>
					<v-carousel
						v-else
						height="auto"
						v-bind:value="carouselCurrentIndex"
						@change="OnCarouselCurrentIndex"
						:continuous="false"
						:hide-delimiters="true"
						class=""
						prev-icon="fas fa-angle-left"
						next-icon="fas fa-angle-right"
					>
						<v-carousel-item v-for="(file, i) in Files" :key="i">
							<v-row
								class="fill-height ma-0 fullscreenContainer"
								align="center"
								justify="center"
							>
								<v-img
									class="carouselImage"
									contain
									v-if="file.Metadata && file.Metadata.PreviewFile"
									:key="file.Uri"
									:src="file.Uri + SasToken"
									:lazy-src="file.Metadata.PreviewFile + SasToken"
									><template v-slot:placeholder>
										<v-row class="fill-height ma-0" align="center" justify="center">
											<v-progress-circular
												indeterminate
												color="grey lighten-2"
											></v-progress-circular>
										</v-row>
									</template>
								</v-img>
								<template v-else-if="file.Metadata && file.Metadata.type === 'video'">
									<v-row class="fill-height ma-0" align="center" justify="center">
										<vueVimeoPlayer class="vimeo" :video-url="file.Metadata.video_url">
										</vueVimeoPlayer>
									</v-row>
								</template>
								<template v-else>
									<v-row class="fill-height ma-0" align="center" justify="center">
										<div class="text-center grey--text">
											<v-icon size="120" color="grey">fas fa-file-image </v-icon>
											<div class="ma-3 text-h5">
												{{ file.DisplayName }}
											</div>
										</div>
									</v-row>
								</template>
							</v-row>
						</v-carousel-item>
					</v-carousel>
				</v-col>
			</v-row>
		</v-container>
	</v-dialog>
</template>
<script>
// Import the editor default configuration
import {
	getEditorDefaults,
	createDefaultImageWriter,
} from "@/pintura/pintura/pintura";

// Import the component from `vue-pintura`
import "@/pintura/pintura/pintura.css";
import { vueVimeoPlayer } from "vue-vimeo-player";

import PinturaEditor from "@/pintura/components/PinturaEditor.js";

import { mapActions, mapGetters } from "vuex";
export default {
	name: "EventDetailsImageDialog",
	components: {
		PinturaEditor: PinturaEditor,
		vueVimeoPlayer,
	},
	props: {
		value: { type: Boolean, required: true },
		EventId: { type: Number, required: true },
		Files: { type: Array, required: true },
		carouselCurrentIndex: { type: Number },
		SgpEventsService: { type: Object, required: true },
	},
	data() {
		return {
			// Pass the editor default configuration options
			editorDefaults: {
				...getEditorDefaults(),
				enableButtonExport: false,
				cropSelectPresetOptions: [
					[
						"Crop",
						[
							[undefined, "Custom"],
							[1, "Square"],
							[4 / 3, "Landscape (4/3)"],
							[3 / 4, "Portrait (3/4)"],
							[16 / 9, "Landscape (16/9)"],
							[9 / 16, "Portrait (9/16)"],
						],
					],
				],
				imageWriter: createDefaultImageWriter({
					/* Image writer properties */
					quality: 0.95,
				}),
			},
			showImageEditor: false,
			playerWidth: 400,
			playerHeight: 300,
		};
	},
	computed: {
		...mapGetters(["SasToken"]),

		selectedFileName() {
			if (this.Files[this.carouselCurrentIndex]) {
				return this.Files[this.carouselCurrentIndex].Uri.split("/").pop();
			}
			return null;
		},
	},
	watch: {
		value: async function (val) {
			this.$log.debug("watch value");
			this.$log.debug(val);

			// if (!val) this.ClearNewUserDialogForm();
		},
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		ShowEditor(file) {
			this.showImageEditor = true;
			this.src = file.Uri;
		},

		HideEditor() {
			this.showImageEditor = false;
			this.src = null;
		},

		async SaveImage() {
			this.$log.debug(this.$refs.pinturaEditor);

			await this.$refs.pinturaEditor.editor.processImage();
			this.$log.debug("Image Processed");
		},

		async SaveEditedImage(processInfo) {
			// TODO: add processing image
			const blobFile = await this.SgpEventsService.UpdateImage(
				this.EventId,
				this.selectedFileName,
				processInfo.dest,
				"postimage?isEditedVersion=true"
			);

			// this.$log.debug("blobFile created");
			// this.$log.debug(blobFile);

			blobFile[0].Selected = false;
			// reactive insert to files
			this.Files.splice(this.carouselCurrentIndex + 1, 0, blobFile[0]);

			// close editor and load new image
			this.showImageEditor = false;

			this.$emit("carousel-current-index-changed", this.carouselCurrentIndex + 1);
			// this.carouselCurrentIndex++;
		},
		HideImageDialog() {
			this.$emit("input", false);
			this.HideEditor();
		},

		OnCarouselCurrentIndex(param) {
			this.$emit("carousel-current-index-changed", param);

			//
		},
		carouselResize() {
			this.$log.debug("carouselResize");
		},
	},
};
</script>
<style lang="scss">
.pintura-editor {
	--color-background: 0, 0, 0;
	--color-foreground: 255, 255, 255;
}

.vimeo iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
</style>
<style lang="scss" scoped>
.fullscreenContainer {
	height: calc(100vh - 48px) !important;
	/* max-height: calc(100vh-48px); */
}

.carouselImage {
	width: 100%;
	height: 100%;
	object-fit: contain;
	display: block;
}
</style>
