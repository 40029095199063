<template>
	<div>
		<vue-headful
			:title="$t('pageTitle')"
			:description="$t('pageMetaDescription')"
		/>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
			<v-icon class="secondary--text text--lighten-2"
				>fas fa-caret-right</v-icon
			>
      </template>
		</v-breadcrumbs>

		<h1 class="text-h3">
			<span>{{ $t("pageTitle") }}</span>
		</h1>

		<v-row>
			<v-col cols="4" md="2">
				<v-checkbox
					v-model="showPhotos"
					label="Visualizza  le foto"
					color="info"
				></v-checkbox>
			</v-col>
			<v-spacer></v-spacer>

			<v-col cols="4" md="2">
				<v-select
					:items="invoiceFilters"
					item-text="Text"
					item-value="Value"
					v-model="filterByInvoiceStatus"
					label="Stato Fatturazione"
					@change="LoadOrders"
				>
				</v-select>
			</v-col>
			<v-col cols="4" md="2">
				<SgpEventPicker
					v-model="SelectedEventId"
					@change="LoadOrders"
					:disabled="SelectEventDisabled"
				></SgpEventPicker>
			</v-col>
			<v-col cols="4" md="2">
				<ClientAccountWithTagsPicker
					v-model="SelectedUserId"
					@change="LoadOrders"
				></ClientAccountWithTagsPicker>
			</v-col>
		</v-row>

		<v-alert dark color="info" v-if="!loadingOrders && Orders.length === 0"
			>{{ $t("NoOrdersFound") }}
		</v-alert>
		<v-progress-linear indeterminate v-if="loadingOrders"></v-progress-linear>
		<div ref="offerPagingStart"></div>
		<v-row v-for="order in Orders" :key="order.OrderId" class="mb-2">
			<v-col cols="2">
				<span class="text-overline"
					>{{ $t("sgp.orderId") }} {{ order.OrderId }}
				</span>
				<sgpOrderStatusChip :StatusId="order.StatusId"></sgpOrderStatusChip>

				<template v-if="order.SGPOrderTransactions.length > 0">
					<v-icon color="primary" class="ml-3">fab fa-paypal</v-icon></template
				>
				<br />
				<span class="text-body-2"> {{ order.CreateDate | formatDate }}</span>
			</v-col>

			<v-col>
				<span class="font-weight-bold pr-1">{{ $t("sgp.event") }}:</span>
				{{ order.SGPEvent.Name }} del
				{{ (order.ExactEventDate || order.SGPEvent.EventDate) | formatDate }}
				<br />
				<span class="font-weight-bold pr-1">{{ $t("sgp.client") }}: </span>
				<UserPublicProfileName :UserId="order.ClientUserId"></UserPublicProfileName>
				<v-chip
					v-if="order.SkillId"
					color="orange darken-3"
					class="ml-3"
					dark
					small
				>
					<v-icon left x-small>fas fa-tag </v-icon>
					{{ order.Skill.Name }}</v-chip
				>

				/
				<UserPublicProfileName
					v-if="order.CreatedBy && order.CreatedBy !== order.ClientUserId"
					:UserId="order.CreatedBy"
				></UserPublicProfileName>

				<br />
				<div v-if="!showPhotos">
					<span
						class="text-caption mr-2"
						v-for="item in order.SGPOrderItems"
						:key="item.OrderItemId"
						>{{ item.FileName }}</span
					>
				</div>
				<div v-else>
					<PhotoGrid :Files="order.SGPOrderItems" class="mt-5" :targetHeight="100">
						<template v-slot:cell="{ file }">
							<OrderImage v-if="file.MediaTypeId === 1" :file="file">
								<div class="pa-3 black white--text" style="opacity: 0.6">
									{{ file.FileName }}
								</div>
							</OrderImage>
							<VimeoThumbnail
								v-else
								:src="file.ExternalPreviewUrl"
								:alt="file.FileName"
							></VimeoThumbnail>
						</template>
					</PhotoGrid>
				</div>
			</v-col>

			<v-col>
				<template
					v-if="
						order.SGPOrderItems.length > 0 &&
						order.SGPOrderItems[0].PhotographyLicense
					"
				>
					<span class="font-weight-bold pr-1">{{ $t("sgp.license") }}:</span>
					{{ order.SGPOrderItems[0].PhotographyLicense.Name }}

					<br />
					<template v-if="order.CalculateTotalByItems">
						<span class="font-weight-bold pr-1">{{ $t("common.price") }}:</span>
						{{ order.SGPOrderItems[0].Price | formatCurrency }} x
						{{ order.SGPOrderItems.length }}
						<br />
					</template>
				</template>

				<span class="font-weight-bold pr-1">{{ $t("common.total") }}:</span>
				{{ order.Total | formatCurrency }} {{ $t("sgp.plusVAT") }} <br
			/></v-col>
			<v-col cols="1">
				<v-chip
					v-if="!order.ExternalInvoiceId"
					@click="ShowExternalDialog(order)"
					color="warning"
					class="ml-2"
					>da fatturare</v-chip
				>
				<v-chip
					v-else
					@click="ShowExternalDialog(order)"
					color="green darken-1"
					dark
					class="ml-2"
					>Id Consegna: {{ order.ExternalInvoiceId }}
				</v-chip>
			</v-col>
		</v-row>

		<ExternalInvoiceIdDialog
			:Mode="2"
			v-if="selectedOrder"
			:ItemId="selectedOrder.OrderId"
			v-model="showExternalInvoiceIdDialog"
			@updated="LoadOrders"
			:initialValues="selectedOrder"
		>
		</ExternalInvoiceIdDialog>

		<div class="text-center" v-if="itemsPaging">
			<v-pagination
				:disabled="loadingOrders"
				v-model="currentPage"
				:length="itemsPaging.TotalPages"
				:total-visible="5"
			></v-pagination>
		</div>
	</div>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import SgpEventPicker from "@/components/Event/SgpEventPicker";
import ClientAccountWithTagsPicker from "@/components/Account/ClientAccountWithTagsPicker.vue";
import ExternalInvoiceIdDialog from "./components/AddEditExternalInvoiceIdDialog.vue";
import UserPublicProfileName from "@/components/Shared/UI/UserPublicProfileName";
import sgpOrderStatusChip from "./components/sgpOrderStatusChip.vue";
import PhotoGrid from "@/components/Shared/UI/PhotoGrid.vue";
import VimeoThumbnail from "@/components/Shared/UI/vimeoThumbnail.vue";
import OrderImage from "@/components/Shared/UI/OrderImage.vue";

import { mapActions } from "vuex";

export default {
	name: "OrdersListView",
	components: {
		SgpEventPicker,
		ClientAccountWithTagsPicker,
		ExternalInvoiceIdDialog,
		UserPublicProfileName,
		sgpOrderStatusChip,
		PhotoGrid,
		VimeoThumbnail,
		OrderImage,
	},
	data() {
		return {
			Orders: [],
			itemsPaging: null, // TotalItems, Limit, Offset, CurrentPageIndex, TotalPages
			currentPage: 1,
			pageSize: 50,
			invoiceFilters: [
				{ Text: "Tutti", Value: null },
				{ Text: "Ordini fatturati", Value: 1 },
				{ Text: "Ordini non fatturati", Value: 2 },
			],
			filterByInvoiceStatus: null,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],

			loadingOrders: false,
			showPhotos: false,

			SelectedEventId: null,
			SelectedUserId: null,
			SelectEventDisabled: false,
			eventDetails: null,
			showExternalInvoiceIdDialog: false,
			selectedOrder: null,
		};
	},
	computed: {},
	watch: {
		async currentPage() {
			await this.LoadOrders();
			this.scrollToElement("offerPagingStart");
		},
	},
	created() {
		this.SgpOrdersService = new CrudClient("SGP/Orders");
	},
	async mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("sgp.orders"),
			disabled: false,
			exact: true,
			to: "/Orders",
		});

		await this.LoadOrders();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadOrders() {
			this.$log.debug("Loading Orders");
			let filter = "SGPOffer.OfferType:noteq:2";

			if (this.SelectedEventId) {
				if (filter !== "") filter += ";";
				filter = "EventId:" + this.SelectedEventId;
			}

			if (this.SelectedUserId) {
				const clientUserIdWithTagParts = this.SelectedUserId.split("$");
				const selectedUserId = clientUserIdWithTagParts[0];
				const skillId = clientUserIdWithTagParts[1];

				if (filter !== "") filter += ";";

				filter += "ClientUserId:" + selectedUserId;

				if (skillId) {
					if (filter !== "") filter += ";";
					filter += "SkillId:" + skillId;
				}
			}

			if (this.filterByInvoiceStatus) {
				if (filter !== "") filter += ";";

				filter +=
					"ExternalInvoiceId:" +
					(this.filterByInvoiceStatus === 1 ? "notnull" : "isnull");
			}

			try {
				this.loadingOrders = true;
				const res = await this.SgpOrdersService.GetPaged({
					limit: this.pageSize,
					skip: (this.currentPage - 1) * this.pageSize,
					orderBy: "OrderId:desc",
					filter: filter,
					fields: `*,
					SGPEvent.*,
					SGPOffer.Title, SGPOffer.OfferType,
					SGPOrderItems.*, SGPOrderItems.PhotographyLicense.Name,
					SGPOrderStatus.*, Skill.Name,
					SGPOrderTransactions.*`,
				});

				this.$log.debug(res);
				this.Orders = res.Data;
				this.itemsPaging = res.Paging;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.loadingOrders = false;
			}
		},
		scrollToElement(element, container, enableSmooth = true) {
			if (this.$refs[element]) {
				(container || window).scrollTo({
					top: this.$refs[element].offsetTop,
					left: 0,
					behavior: enableSmooth ? "smooth" : undefined,
				});
			}
		},

		ShowExternalDialog(order) {
			this.selectedOrder = order;
			this.showExternalInvoiceIdDialog = true;
		},
	},
};
</script>
<i18n>
{
	"it":{
		"pageTitle": "Ordini",
		"pageMetaDescription": "Tutti ordini",
		"NoOrdersFound": "Nessun ordine trovato",
		"orderDate": "Data ordine"
	}
}
</i18n>
