<template>
	<v-img
		:src="file.PreviewSrc + SasToken"
		:aspect-ratio="file.AspectRatio"
		class="grey darken-4 rounded"
		v-bind="$attrs"
		v-on="$listeners"
		@click="$emit('click')"
	>
		<slot></slot>
		<template v-slot:placeholder>
			<v-row class="fill-height ma-0" align="center" justify="center">
				<v-progress-circular
					indeterminate
					color="grey lighten-2"
				></v-progress-circular>
			</v-row>
		</template>
	</v-img>
</template>
<script>
import { mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters(["SasToken"]),
	},
	props: {
		file: {
			type: Object,
		},
	},
};
</script>
