<template>
	<div @keydown.esc="DeselectImages">
		<confirm ref="confirm"></confirm>
		<vue-headful v-if="eventDetails" :title="eventDetails.Name" :description="$t('pageMetaDescription')" />
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
			<v-icon class="secondary--text text--lighten-2">fas fa-caret-right</v-icon>
      </template>
		</v-breadcrumbs>

		<v-toolbar color="rounded" v-if="eventDetails">
			<v-toolbar-items v-if="selectedFilesCount">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon @click="SelectAll(false)" v-bind="attrs" v-on="on">
							<v-icon>fas fa-times-circle</v-icon>
						</v-btn>
					</template>
					<span>{{ $t("unselectFiles") }}</span>
				</v-tooltip>
			</v-toolbar-items>
			<v-toolbar-items v-else>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon @click="SelectAll(true)" v-bind="attrs" v-on="on">
							<v-icon>fas fa-check-circle</v-icon>
						</v-btn>
					</template>
					<span>{{ $t("selectAll") }}</span>
				</v-tooltip>
			</v-toolbar-items>
			<v-toolbar-title v-if="!selectedFilesCount">{{ eventDetails.Name }}
			</v-toolbar-title>
			<v-toolbar-title v-else>
				{{ selectedFilesCount }}
				{{ selectedFilesCount == 1 ? $t("fileSelected") : $t("filesSelected") }}
			</v-toolbar-title>

			<v-spacer></v-spacer>
			<v-toolbar-items>
				<template v-if="!selectedFilesCount">
					<v-btn v-if="isPhotographerMode" icon @click="showCreateUserDialog = true"> <v-icon>fas
							fa-user-plus</v-icon></v-btn>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon :to="EventId + '/Offers'" v-bind="attrs" v-on="on">
								<v-icon>fas fa-camera-alt</v-icon>
							</v-btn>
						</template>
						<span>{{ $t("eventOffersTooltip") }}</span>
					</v-tooltip>
					<v-tooltip bottom v-if="!isPhotographerMode">
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon :to="EventId + '/Orders'" v-bind="attrs" v-on="on">
								<v-icon>fas fa-fw fa-photo-video</v-icon>
							</v-btn>
						</template>
						<span>{{ $t("eventOrdersTooltip") }}</span>
					</v-tooltip>

					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon @click="OpenVimeoDialog" v-bind="attrs" v-on="on">
								<v-icon>fab fa-vimeo</v-icon>
							</v-btn>
						</template>
						<span>{{ $t("addVimeoVideoTooltip") }}</span>
					</v-tooltip>

					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon @click="pickFile" v-bind="attrs" v-on="on">
								<v-icon>fas fa-upload</v-icon>
							</v-btn>
						</template>
						<span>{{ $t("common.uploadTooltip") }}</span>
					</v-tooltip>

					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon @click="LoadStorageFolderFiles" v-bind="attrs" v-on="on">
								<v-icon>fas fa-sync-alt</v-icon>
							</v-btn>
						</template>
						<span>{{ $t("common.refreshData") }}</span>
					</v-tooltip>

					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon @click="SwitchView" :color="flatFilesView ? '' : 'info'" v-bind="attrs"
								v-on="on">
								<v-icon>fas fa-folder-tree</v-icon>
							</v-btn>
						</template>
						<span>{{
							flatFilesView ? $t("enableFolderView") : $t("enableFlatFilesView")
							}}</span>
					</v-tooltip>
				</template>

				<template v-else>
					<v-btn @click="ShowCopyrightUpdateDialog" icon v-if="isPhotoOffer">
						<v-icon>fas fa-copyright</v-icon>
					</v-btn>

					<v-btn icon @click=" DeleteSelectedImages">
						<v-icon>fas fa-trash</v-icon>
					</v-btn>
					<v-menu offset-y v-if="isPhotoOffer || isVideoOffer">
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon v-bind="attrs" v-on="on">
								<v-icon>fas fa-share-alt</v-icon>
							</v-btn>
						</template>
						<v-list>
							<v-list-item @click="(createOfferDialogMode = 1), (showCreateOfferDialog = true)">
								<v-list-item-title>Crea Proposta</v-list-item-title>
							</v-list-item>
							<v-list-item @click="(createOfferDialogMode = 2), (showCreateOfferDialog = true)">
								<v-list-item-title>Crea Proposta per approvazione</v-list-item-title>
							</v-list-item>
							<v-list-item @click="showDirectDeliveryDialog = true" v-if="isPhotoOffer">
								<v-list-item-title>{{
									$t("sgp.directDeliveryOrder")
									}}</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</template>
			</v-toolbar-items>

			<div slot="extension" v-if="!flatFilesView" class="">
				<FolderViewNavigation :Path="currentSubFolder" @navigate-to="navigateToSubFolder($event)">
				</FolderViewNavigation>
			</div>
		</v-toolbar>
		<div style="display: none">
			<v-file-input multiple @change="fileUploadSelected" v-model="uploadFiles" ref="inputFiles"></v-file-input>
		</div>

		<CreateUserDialog v-if="isPhotographerMode" v-model="showCreateUserDialog" @updated="onUserCreated"
			addToRole="Client" :photographerMode="isPhotographerMode" dialogHeader="Nuovo Cliente">
		</CreateUserDialog>

		<v-progress-linear indeterminate v-if="LoadingImages"> </v-progress-linear>
		<v-container @drop.stop.prevent="dropFile" @dragover.prevent="dragOverHandler" @dragenter="setDragOverlay"
			@dragleave="setDragOverlay">
			<v-overlay v-if="dragOverlay" :absolute="false" opacity="0.6">
				<!-- <v-progress-circular indeterminate size="64"></v-progress-circular> -->
				<v-icon size="120" color="grey">fas fa-upload</v-icon>
			</v-overlay>

			<FileUploadQueue :uploadQueue="uploadQueue" :currentSubFolder="currentSubFolder" :EventId="EventId"
				@fileUploaded="OnFileUploaded"></FileUploadQueue>
			<v-row>
				<v-col class="pa-1" v-if="!LoadingImages && Files.length === 0">
					<v-alert dark class="info"> Nessun file in evento </v-alert>
				</v-col>
			</v-row>

			<PhotoGrid :Files="Files" class="mt-5 mx-1" :targetHeight="200">
				<template v-slot:cell="{ file, index }">
					<v-responsive aspect-ratio="1.2" class="rounded elevation-3 pointer" v-if="file.IsDirectory"
						@click="setWorkingSubFolder(file)">
						<v-row class="fill-height ma-0" align="center" justify="center">
							<div class="text-center">
								<v-icon size="60" color="yellow darken-2">fas fa-folder </v-icon>
								<br />

								{{ file.DisplayName }}
							</div>
						</v-row>
					</v-responsive>
					<template v-else>
						<SelectableItem v-if="file.Metadata" v-model="file.Selected"
							@selectClick="onItemSelected($event, index)"
							@unselectClick="onItemUnselected($event, index)" @zoomClick="ShowImageDialog(file, index)">
							<!-- @click="" -->
							<AzurePreviewImage v-if="file.Metadata.PreviewFile" :file="file" class="darkened-image">
							</AzurePreviewImage>

							<VimeoThumbnail v-else-if="file.Metadata.type === 'video'"
								:src="file.Metadata.thumbnail_url" :alt="file.Metadata.title"
								@click="ShowImageDialog(file, index)">
							</VimeoThumbnail>
						</SelectableItem>

						<v-responsive aspect-ratio="1.2" v-else>
							<v-row class="fill-height ma-0" align="center" justify="center">
								<div class="text-center">
									<v-icon size="60">fas fa-file-image </v-icon>
									<br />
									{{ file.DisplayName }}
								</div>
							</v-row>
						</v-responsive>
					</template>
				</template>
			</PhotoGrid>
		</v-container>
		<eventDirectDeliveryDialog v-model="showDirectDeliveryDialog" :EventId="EventId" :SelectedFiles="SelectedFiles">
		</eventDirectDeliveryDialog>
		<eventDetailsDialog v-model="showImageDialog" :EventId="EventId" :SgpEventsService="SgpEventsService"
			v-bind:carouselCurrentIndex="carouselCurrentIndex" @carousel-current-index-changed="OnCarouselIndexChanged"
			:Files="Files"></eventDetailsDialog>
		<v-dialog v-model="vimeoDialog.showDialog" max-width="500px">
			<v-card>
				<v-card-title> Aggiungi Video </v-card-title>
				<v-card-text>
					<v-text-field label="video url" v-model="vimeoDialog.url"
						hint="https://vimeo.com/597676212/465d9b6da7"></v-text-field></v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="PostVimeo" :disabled="!vimeoDialog.url">
						{{ $t("common.add") }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<createOfferDialog
			v-model="showCreateOfferDialog"
			:OfferType="createOfferDialogMode"
			:isVideoOffer="isVideoOffer"
			:EventId="EventId"
			:SelectedFiles="SelectedFiles">
		</createOfferDialog>

		<UpdateCopyrightDialog v-model="copyrightDialog.showUpdateCopyrightDialog" @ExifUpdated="ExifUpdated"
			:EventId="EventId" :SelectedFiles="SelectedFiles"></UpdateCopyrightDialog>
	</div>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import { mapActions, mapGetters } from "vuex";

import eventDetailsDialog from "./EventDetailsImageDialog.vue";
import eventDirectDeliveryDialog from "./CreateDirectDeliveryOrder.vue";
import createOfferDialog from "./CreateOfferDialog.vue";
import Confirm from "@/components/Shared/Common/Confirm";
import SelectableItem from "@/components/Shared/Common/SelectableItemV2.vue";
import VimeoThumbnail from "@/components/Shared/UI/vimeoThumbnail.vue";
import AzurePreviewImage from "@/components/Shared/UI/AzurePreviewImage.vue";
import UpdateCopyrightDialog from "./components/UpdateCopyrightDialog";
import FileUploadQueue from "./components/FileUploadQueue.vue";

import FolderViewNavigation from "@/components/Shared/UI/FolderViewNavigation.vue";

import PhotoGrid from "@/components/Shared/UI/PhotoGrid";
import CreateUserDialog from "@/components/Account/CreateUserDialog"

import { QueueStatus } from "./components/queueStatusEnum.js";

export default {
	name: "EventDetails",
	components: {
		eventDetailsDialog,
		Confirm,
		createOfferDialog,
		eventDirectDeliveryDialog,
		SelectableItem,
		PhotoGrid,
		VimeoThumbnail,
		AzurePreviewImage,
		FolderViewNavigation,
		FileUploadQueue,
		UpdateCopyrightDialog,
		CreateUserDialog
	},
	data() {
		return {
			vimeoDialog: {
				showDialog: false,
				url: "",
			},

			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
			eventDetails: null,
			LoadingImages: false,

			flatFilesView: false,
			currentSubFolder: null,
			Files: [],
			lastSelectedFileIndex: null,

			showImageDialog: false,

			uploadFiles: [], // binded to file-input control
			uploadQueue: [], // file-input files are added to the queue

			carouselCurrentIndex: null,

			dragOverlay: 0,
			showCreateOfferDialog: false,
			createOfferDialogMode: 1,
			showDirectDeliveryDialog: false,

			GridView: [],
			copyrightDialog: { showUpdateCopyrightDialog: false },

			showCreateUserDialog: false
		};
	},
	computed: {
		...mapGetters([
			"isUserInRole",
		]),
		EventId() {
			return parseInt(this.$route.params.EventId);
		},

		selectedFilesCount() {
			return this.Files.filter((f) => f.Selected).length;
		},

		SelectedFiles() {
			return this.Files.filter((f) => f.Selected);
		},

		isPhotographerMode() {
			return (!this.isUserInRole("BusinessAdmin") && this.isUserInRole("Photographer"));
		},

		isPhotoOffer() { return this.SelectedFiles.every(item => item.Metadata.PreviewFile !== undefined); },

		isVideoOffer() { return this.SelectedFiles.every(item => item.Metadata.type === "video"); }
	},
	created() {
		this.SgpEventsService = new CrudClient("SGP/Events");

		this.QueueStatus = QueueStatus;
	},
	async mounted() {
		this.eventDetails = await this.SgpEventsService.Get(this.EventId);

		this.breadcrumbsItems.push({
			text: this.$t("sgp.events"),
			disabled: false,
			exact: true,
			to: "/Events",
		});
		this.breadcrumbsItems.push({
			text: this.eventDetails.Name,
			disabled: true,
			exact: true,
			to: "/Events/" + this.EventId,
		});

		await this.LoadStorageFolderFiles();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		onItemSelected(event, index) {
			this.$log.debug("input: " + index);

			if (
				this.lastSelectedFileIndex != null &&
				this.lastSelectedFileIndex !== index
			) {
				if (event.shiftKey) {
					const start =
						this.lastSelectedFileIndex < index ? this.lastSelectedFileIndex : index;
					const end =
						this.lastSelectedFileIndex > index ? this.lastSelectedFileIndex : index;

					for (let i = start; i < end; i++) {
						this.Files[i].Selected = true;
					}
				}
			}
			this.lastSelectedFileIndex = index;
		},

		onItemUnselected() {
			this.lastSelectedFileIndex = null;
		},

		dragOverHandler(e) {
			e.dataTransfer.dropEffect = "move";
		},

		setDragOverlay(e) {
			if (e.type === "dragenter") {
				this.dragOverlay++;
			}
			if (e.type === "dragleave") {
				this.dragOverlay--;
			}
		},

		async dropFile(e) {
			this.dragOverlay = 0;
			// if (this.uploadingFiles) {
			// 	return;
			// }

			const droppedFiles = [...e.dataTransfer.files];
			if (!droppedFiles) return;

			// this.checkFileSize(droppedFiles);

			droppedFiles.forEach((element) => {
				element.url = window.URL.createObjectURL(element);
				element.uploadStatus = this.QueueStatus.QUEUED;

				this.uploadQueue.push(element);
			});

			// await this.UploadFilesFromQueue();
		},

		OnFileUploaded(file) {
			this.Files.push(file);
		},

		ShowImageDialog(file, index) {
			this.$log.debug(file);
			this.$log.debug(index);

			this.showImageDialog = true;
			this.carouselCurrentIndex = index;
		},

		async SwitchView() {
			if (this.LoadingImages) return;
			this.flatFilesView = !this.flatFilesView;
			this.currentSubFolder = null;

			await this.LoadStorageFolderFiles();
		},

		async navigateToSubFolder(path) {
			if (this.LoadingImages) return;
			this.currentSubFolder = path;

			await this.LoadStorageFolderFiles();
		},

		async setWorkingSubFolder(file) {
			if (this.LoadingImages) return;
			if (file) {
				if (!this.currentSubFolder) this.currentSubFolder = "";

				this.currentSubFolder += file.DisplayName + "/";
			} else {
				this.currentSubFolder = null;
			}

			await this.LoadStorageFolderFiles();
		},

		async LoadStorageFolderFiles() {
			this.LoadingImages = true;
			try {
				let parameters = "flatView=" + this.flatFilesView;
				if (this.currentSubFolder) {
					parameters += "&subFolder=" + encodeURIComponent(this.currentSubFolder);
				}

				const orderBy = "FileName";
				parameters += "&orderBy=" + orderBy;

				const res = await this.SgpEventsService.Get(
					this.EventId,
					"Files?" + parameters
				);

				this.lastSelectedFileIndex = null;
				res.forEach((f) => (f.Selected = false));

				this.Files = res;
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingImages = false;
			}
		},

		SelectAll(val) {
			this.Files.filter((f) => !f.IsDirectory).forEach((f) => (f.Selected = val));
		},

		async DeleteSelectedImages() {
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					this.$t("common.confirmDelete")
				))
			) {
				return;
			}

			const selectedFiles = this.Files.filter((f) => f.Selected).map(
				(f) => f.Path
			);

			this.LoadingImages = true;
			try {
				await this.SgpEventsService.Post(
					this.EventId,
					selectedFiles,
					"DeleteFiles",
					false
				);

				// reactively remove selected images
				selectedFiles.forEach((selectedFile) => {
					this.Files.splice(
						this.Files.findIndex((f) => f.Path === selectedFile),
						1
					);
				});
			} catch (error) {
				this.snackError("common.error.cannotDelete");
				this.$captureError(error);
			} finally {
				this.LoadingImages = false;
			}
		},

		pickFile() {
			this.$refs.inputFiles.$refs.input.click();
		},

		async fileUploadSelected() {
			this.$log.debug(this.uploadFiles);
			for (const file of this.uploadFiles) {
				file.url = window.URL.createObjectURL(file);
				file.uploadStatus = this.QueueStatus.QUEUED;
				this.uploadQueue.push(file);
			}
			this.uploadFiles = [];

			// await this.UploadFilesFromQueue();
			// this.$log.debug("UploadFilesFromQueue ended");
		},

		OnCarouselIndexChanged(index) {
			this.carouselCurrentIndex = index;
		},

		OpenVimeoDialog() {
			this.vimeoDialog.showDialog = true;
		},

		async PostVimeo() {
			try {
				const blobFile = await this.SgpEventsService.Post(
					this.EventId,
					{ url: this.vimeoDialog.url },
					"postvimeo",
					true
				);

				blobFile.Selected = false;
				this.Files.unshift(blobFile);

				this.vimeoDialog.showDialog = false;
				this.vimeoDialog.url = "";
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: "Impossibile caricare il video!" });
			}
		},

		ExifUpdated(item) {
			this.Files.find((f) => f.Path === item.Path).Metadata = item.Metadata;
		},

		ShowCopyrightUpdateDialog() {
			this.copyrightDialog.showUpdateCopyrightDialog = true;
		},

		onUserCreated() {
			this.snackSuccss({ Text: "Utente creato" });
		}
	},
};
</script>

<style lang="scss" scoped>
.darkened-image {
	filter: brightness(100%);
	transition: 0.3s;

	&:hover {
		filter: brightness(80%);
	}
}
</style>
<i18n>
{
	"it":{
		"fileSelected": "file selezionato",
		"filesSelected": "file selezionati",
		"unselectFiles": "Deseleziona i file",
		"addVimeoVideoTooltip": "Aggiungi Vimeo video",
		"eventOffersTooltip": "Proposte dell'evento",
		"eventOrdersTooltip": "Ordini dell'evento",
		"selectAll": "Seleziona tutti i file",
		"enableFolderView":"Abilita vista cartelle",
		"enableFlatFilesView":"Visualizza tutte le foto"
	}
}
</i18n>
