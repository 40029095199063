var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mb-2"},[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-img',{staticClass:"rounded",attrs:{"max-width":"150","src":_vm.detectedFace.path + _vm.SasToken}})],1),_c('v-col',{attrs:{"cols":"8"}},[_vm._l((_vm.detectedFace.identifyResults),function(identifyResult,index){return _c('v-card',{key:index,attrs:{"flat":""}},[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","color":"warning","icon":""},on:{"click":function($event){return _vm.$emit('ignoreFace', _vm.detectedFace)},"keydown":function($event){$event.preventDefault();return _vm.$log.debug('button key down')}}},[_c('v-icon',[_vm._v("fas fa-eraser")])],1)],1),_vm._l((identifyResult),function(candidate){return _c('v-card-actions',{key:candidate.PersonId},[_c('FacePersonNameLabel',{attrs:{"facePersonId":candidate.PersonId,"confidence":candidate.Confidence}}),(
								_vm.detectedFace.QualityForRecognition !== 'Low' &&
								!(
									_vm.originalImage.Metadata['TrainingModelFaces'] &&
									_vm.originalImage.Metadata['TrainingModelFaces'].includes(
										_vm.detectedFace.FaceId
									)
								)
							)?_c('v-icon',{staticClass:"ml-3",attrs:{"color":"info"},on:{"click":function($event){return _vm.addToRecognizedFaceToModel(candidate)}}},[_vm._v("fas fa-plus-circle")]):_vm._e()],1)})],2)}),(_vm.detectedFace.QualityForRecognition === 'Low')?_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" Qualita bassa per riconoscimento ")]):(
						!_vm.isPersonRecognized ||
						_vm.detectedFace.identifyResults[0][0].Confidence < 0.95
					)?[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.isNewPerson),callback:function ($$v) {_vm.isNewPerson=$$v},expression:"isNewPerson"}},[_c('v-radio',{attrs:{"value":true,"label":"Nuova persona"}}),_c('v-radio',{attrs:{"value":false,"label":"Persona esistente"}})],1),(_vm.isNewPerson)?_c('v-text-field',{attrs:{"label":_vm.$t('common.name')},on:{"keydown":function($event){$event.stopPropagation();}},model:{value:(_vm.facePersonName),callback:function ($$v) {_vm.facePersonName=$$v},expression:"facePersonName"}}):_c('SGPFacePersonPicker',{on:{"keydown":function($event){$event.stopPropagation();}},model:{value:(_vm.selectedFacePersonId),callback:function ($$v) {_vm.selectedFacePersonId=$$v},expression:"selectedFacePersonId"}}),_c('v-btn',{attrs:{"loading":_vm.addingPerson,"color":"primary"},on:{"click":_vm.addPerson}},[_vm._v("Add to model")])]:_vm._e()],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }