<template>
	<div>
		<vue-headful :title="$t('pageTitle')" :description="$t('pageMetaDescription')" />
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon  class="secondary--text text--lighten-2">fas fa-caret-right</v-icon>
      </template>
		</v-breadcrumbs>

		<h1 class="text-h3">
			<span v-if="!EventId">{{ $t("pageTitle") }}</span>
			<span v-else-if="eventDetails">Proposte dell'evento {{ eventDetails.Name }}</span>
		</h1>

		<v-row>
			<v-col cols="6" class="text-right"> </v-col>
			<v-col>
				<SgpEventPicker v-model="SelectedEventId" @change="LoadEventOffers" :disabled="SelectEventDisabled">
				</SgpEventPicker>
			</v-col>
			<v-col>
				<ClientAccountWithTagsPicker v-model="SelectedUserId" @change="LoadEventOffers">
				</ClientAccountWithTagsPicker>
			</v-col>
		</v-row>

		<v-alert dark color="info" v-if="!loadingOffers && eventOffers.length === 0">{{ $t("NoOffersForEvent") }}
		</v-alert>
		<v-progress-linear indeterminate v-if="loadingOffers"></v-progress-linear>
		<div class="text-center my-3" v-if="itemsPaging">
			<v-pagination :disabled="loadingOffers" v-model="currentPage" :length="itemsPaging.TotalPages"
				:total-visible="5"></v-pagination>
		</div>
		<div ref="offerPagingStart"></div>
		<AdminCompactOfferCard v-for="offer in eventOffers" :key="offer.OfferId" :offer="offer"
			@offerDeleted="LoadEventOffers" @offerUpdated="LoadEventOffers"></AdminCompactOfferCard>

		<div class="text-center" v-if="itemsPaging">
			<v-pagination :disabled="loadingOffers" v-model="currentPage" :length="itemsPaging.TotalPages"
				:total-visible="5"></v-pagination>
		</div>
	</div>
</template>
<script>
import AdminCompactOfferCard from "./components/AdminCompactOfferCard.vue";
import SgpEventPicker from "@/components/Event/SgpEventPicker";

import { mapActions, mapGetters } from "vuex";

import CrudClient from "@/services/CrudClient/";
import ClientAccountWithTagsPicker from "@/components/Account/ClientAccountWithTagsPicker.vue";

export default {
	name: "AllOffersList",
	components: { AdminCompactOfferCard, SgpEventPicker, ClientAccountWithTagsPicker },
	data() {
		return {
			itemsPaging: null, // TotalItems, Limit, Offset, CurrentPageIndex, TotalPages
			currentPage: 1,
			pageSize: 10,
			eventOffers: [],
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],

			loadingOffers: false,

			SelectedEventId: null,
			SelectedUserId: null,
			SelectEventDisabled: false,
			eventDetails: null,
		};
	},
	props: {},
	computed: {
		...mapGetters([
			"UserProfile",
			"isUserInRole",
			"isUserInRoles",
		]),
		EventId() {
			if (!this.$route.params.EventId) return null;
			return parseInt(this.$route.params.EventId);
		},
	},
	created() {
		this.OffersService = new CrudClient("SGP/Offers");
		this.SgpEventsService = new CrudClient("SGP/Events");
	},
	watch: {
		async currentPage() {
			await this.LoadEventOffers();
			this.scrollToElement("offerPagingStart");
		},
	},
	async mounted() {
		if (!this.EventId) {
			this.breadcrumbsItems.push({
				text: this.$t("sgp.offers"),
				disabled: false,
				exact: true,
				to: "/Offers",
			});
		}
		if (this.EventId) {
			this.SelectEventDisabled = true;
			this.SelectedEventId = this.EventId;

			this.eventDetails = await this.SgpEventsService.Get(this.EventId);

			this.breadcrumbsItems.push({
				text: this.$t("sgp.events"),
				disabled: false,
				exact: true,
				to: "/Events",
			});
			this.breadcrumbsItems.push({
				text: this.eventDetails.Name,
				disabled: false,
				exact: true,
				to: "/Events/" + this.EventId,
			});
			this.breadcrumbsItems.push({
				text: "Proposte",
				disabled: true,
				exact: true,
				to: "/Events/" + this.EventId + "/Offers",
			});
		}

		await this.LoadEventOffers();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadEventOffers() {
			let filter = "";
			if (this.SelectedEventId) {
				filter = "EventId:" + this.SelectedEventId;
			}

			if (this.SelectedUserId) {
				const clientUserIdWithTagParts = this.SelectedUserId.split("$");
				const selectedUserId = clientUserIdWithTagParts[0];
				const skillId = clientUserIdWithTagParts[1];

				if (filter !== "") filter += ";";

				filter += "ClientUserId:" + selectedUserId;

				if (skillId) {
					if (filter !== "") filter += ";";
					filter += "SkillId:" + skillId;
				}
			}

			// if user is a photographer but not an businessadmin
			// filter += "CreatedBy": + this.CurrentUserId

			if (!this.isUserInRole("BusinessAdmin") && this.isUserInRole("Photographer")) {
				if (filter !== "") filter += ";";
				filter += "CreatedBy:" + this.UserProfile.Id;
			}

			try {
				this.loadingOffers = true;
				const res = await this.OffersService.GetPaged({
					limit: this.pageSize,
					skip: (this.currentPage - 1) * this.pageSize,
					orderBy: "OfferId:desc",
					filter: filter,
					fields: `*, SGPOfferStatus.*, SGPOfferPrices.*,
						SGPOfferPrices.PhotographyLicense.Name,
						SGPOfferItems.*, SGPEvent.Name, SGPOfferMails.*,
						SGPOfferMails.SentEmail.*,
						SGPOrders.*,
						Skill.Name`,
				});

				this.eventOffers = res.Data;
				this.itemsPaging = res.Paging;
				this.currentPage = res.Paging.CurrentPageIndex + 1;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.loadingOffers = false;
			}
		},

		scrollToElement(element, container, enableSmooth = true) {
			if (this.$refs[element]) {
				(container || window).scrollTo({
					top: this.$refs[element].offsetTop,
					left: 0,
					behavior: enableSmooth ? "smooth" : undefined,
				});
			}
		},
	},
};
</script>
<i18n>
{"it":{
	"NoOffersForEvent": "Nessun proposta trovata",
	"pageTitle": "Tutte proposte"

}}
</i18n>
