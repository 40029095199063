<template>
	<v-chip>
		<v-chip>{{ StatusName }}</v-chip>
	</v-chip>
</template>

<script>
import { SgpOrderStatusIdEnum } from "./sgpOrderStatus";
export default {
	created() {
		this.SgpOrderStatusIdEnum = SgpOrderStatusIdEnum;
	},
	computed: {
		StatusName() {
			switch (this.StatusId) {
				case this.SgpOrderStatusIdEnum.DRAFT:
					return this.$t("DRAFT");
				case this.SgpOrderStatusIdEnum.SENT:
					return this.$t("SENT");
				case this.SgpOrderStatusIdEnum.INPOSTPROCESSING:
					return this.$t("INPOSTPROCESSING");
				case this.SgpOrderStatusIdEnum.APPROVED:
					return this.$t("APPROVED");
				case this.SgpOrderStatusIdEnum.WAITING_APPROVAL:
					return this.$t("WAITING_APPROVAL");
			}

			return "-";
		},
	},

	props: {
		StatusId: {
			type: Number,
			required: true,
		},
	},
};
</script>

<style>
</style>
<i18n>
{
	"it":{
		"DRAFT": "Bozza",
		"SENT": "Inviato",
		"INPOSTPROCESSING": "In Post-Produzione",
		"APPROVED": "Approvato",
		"WAITING_APPROVAL": "In attesa di approvazione"
	},
	"en":{
		"DRAFT": "Draft",
		"SENT": "Sent",
		"INPOSTPROCESSING": "In Post-Production",
		"APPROVED": "Approved",
		"WAITING_APPROVAL": "Waiting approval"
	}
}
</i18n>
