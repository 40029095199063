<template>
	<v-card>
		<v-card-title>
			<v-badge
				color="green"
				content="R"
				overlap
				v-bind:value="sgpOrderItem.RetouchedUploaded"
			>
				<span class="text-subtitle-2">{{ sgpOrderItem.FileName }}</span>
			</v-badge>
		</v-card-title>
		<v-img
			v-if="!sgpOrderItem.RetouchedUploaded"
			:src="sgpOrderItem.EventFilePreview + SasToken"
			class="grey darken-4 rounded"
		></v-img>
		<v-img
			v-else
			:src="sgpOrderItem.PreviewSrc + SasToken + '&sgp=' + urlCounter"
			class="grey darken-4 rounded"
		></v-img>

		<v-progress-linear class="my-4" v-if="uploadingFile"></v-progress-linear>
		<v-card-actions>
			<v-spacer></v-spacer>

			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						:disabled="uploadingFile"
						icon
						:href="sgpOrderItem.EventFileMedia + SasToken"
						target="_blank"
						v-bind="attrs"
						v-on="on"
					>
						<v-icon>fas fa-download</v-icon>
					</v-btn>
				</template>
				<span>{{ $t("downloadOriginal") }}</span>
			</v-tooltip>

			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						icon
						color="info"
						v-bind="attrs"
						v-on="on"
						@click="pickFile"
						:loading="uploadingFile"
					>
						<v-icon>fas fa-cloud-upload</v-icon>
					</v-btn>
				</template>
				<span>{{ $t("uploadRetouched") }}</span>
			</v-tooltip>

			<div style="display: none">
				<v-file-input
					@change="fileUploadSelected"
					v-model="uploadFiles"
					ref="inputFiles"
				></v-file-input>
			</div>
		</v-card-actions>
	</v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";

export default {
	data() {
		return { uploadFiles: null, uploadingFile: false, urlCounter: 0 };
	},
	props: {
		sgpOrderItem: {
			type: Object,
			required: true,
		},
	},
	computed: {
		...mapGetters(["SasToken"]),
	},
	created() {
		this.SgpOrdersService = new CrudClient("SGP/Orders");
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),
		pickFile() {
			this.$refs.inputFiles.$refs.input.click();
		},
		async fileUploadSelected() {
			try {
				this.uploadingFile = true;

				await this.SgpOrdersService.UpdateImage(
					this.sgpOrderItem.OrderId,
					this.uploadFiles.name,
					this.uploadFiles,
					`${this.sgpOrderItem.MediaId}/uploadRetoucehed`
				);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("error.cannotSaveData") });
			} finally {
				this.uploadingFile = false;
			}

			this.$log.debug(this.sgpOrderItem.FileName + " uploaded");
			this.urlCounter++;
			this.$emit("fileUploaded", this.sgpOrderItem);
		},
	},
};
</script>
<i18n>
{
	"it":
	{
		"retouchedPhotoAdded": "Ritocco caricato",
		"downloadOriginal": "Scarica file originale",
		"uploadRetouched": "Carica ritocco"
	}
}
</i18n>
