<template>
	<div>
		<vue-headful v-if="offer" :title="$t('sgp.offer') + ' ' + offer.OfferId" />
		<v-breadcrumbs :items="breadcrumbsItems">
			<template v-slot:divider>
				<v-icon class="secondary--text text--lighten-2"
					>fas fa-caret-right</v-icon
				>
			</template>
		</v-breadcrumbs>

		<v-progress-linear indeterminate v-if="loadingOffers"></v-progress-linear>
		<AdminOfferCard v-if="offer" :offer="offer"></AdminOfferCard>
	</div>
</template>
<script>
import AdminOfferCard from "./components/AdminOfferCard.vue";
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";
export default {
	components: {
		AdminOfferCard
	},
	data() {
		return {
			offer: null,
			loadingOffers: false,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/"
				}
			]
		};
	},
	computed: {
		OfferId() {
			return parseInt(this.$route.params.OfferId);
		}
	},
	created() {
		this.SgpOffersService = new CrudClient("SGP/Offers");
	},
	async mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("sgp.offers"),
			disabled: false,
			exact: true,
			to: "/Offers"
		});

		this.breadcrumbsItems.push({
			text: this.OfferId,
			disabled: true,
			exact: true,
			to: "/Offers/" + this.OfferId
		});

		await this.LoadOffers();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadOffers() {
			try {
				this.loadingOffers = true;
				this.offer = await this.SgpOffersService.GetSelectedFields(
					this.OfferId,
					`*, SGPOfferStatus.*, SGPOfferPrices.*,
						SGPOfferPrices.PhotographyLicense.Name,
						SGPOfferItems.*,
						SGPOfferItems.SgpApprovalOfferItem.*,
						SGPEvent.Name, SGPOfferMails.*,
						SGPOfferMails.SentEmail.*,
						SGPOrders.*,
						Skill.Name`
				);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.loadingOffers = false;
			}
		}
	}
};
</script>
