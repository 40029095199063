<template>
	<v-dialog
		fullscreen
		persistent
		:value="value"
		@input="$emit('input', $event)"
		@keydown.esc="closeDialog"
	>
		<confirm ref="confirm"></confirm>
		<v-card>
			<v-toolbar color="grey lighten-2">
				<v-toolbar-title>{{
					this.OfferType === SgpOfferTypeEnum.OFFER
						? $t("createOfferTitle")
						: $t("createOfferForApprovalTitle")
				}}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn icon @click="closeDialog"><v-icon>fas fa-times</v-icon></v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-stepper v-if="stepperView" v-model="currentStep">
				<v-stepper-header>
					<v-stepper-step step="1">Dati proposta </v-stepper-step>

					<v-divider></v-divider>
					<template v-if="this.OfferType === SgpOfferTypeEnum.OFFER">
						<v-stepper-step step="2"> Prezzi </v-stepper-step>

						<v-divider></v-divider
					></template>

					<v-stepper-step step="3"> Invia </v-stepper-step>
				</v-stepper-header>

				<v-stepper-items>
					<v-stepper-content step="1">
						<v-card>
							<SimpleFormCard
								v-if="EventDetails"
								v-model="formIsValid"
								:locali18n="$i18n"
								ref="formAddEditOffer"
								:formContent="formAddEditOffer"
								:metadata="metadata"
								:metadataLoaded="metadataLoaded"
								:initialValues="defaultValues"
							>
								<template v-slot:card-actions>
									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn
											class="primary"
											large
											@click="Step1Click"
											:disabled="!formIsValid"
										>
											{{ $t("common.continue") }}</v-btn
										>
									</v-card-actions>
								</template>
							</SimpleFormCard>
						</v-card></v-stepper-content
					>
					<v-stepper-content step="2" class="mx-0 px-0">
						<v-card>
							<v-card-text>
								<v-row dense>
									<v-col cols="2" class="font-weight-bold">Seleziona</v-col>
									<v-col cols="3" class="font-weight-bold">Licenza</v-col>
									<v-col cols="2" class="font-weight-bold">{{
										$t("LicenseDefaultPrice")
									}}</v-col>
									<v-col cols="2" class="font-weight-bold">{{
										$t("ClientDefaultPrice")
									}}</v-col>
									<v-col cols="3" class="font-weight-bold">{{
										$t("OfferPrice")
									}}</v-col>
								</v-row>
								<v-row
									align="baseline"
									dense
									class="px-1 py-0"
									:class="{
										'percentagePadding blue lighten-4': license.Selected
									}"
									v-for="license in CombinedLicensePrices"
									:key="license.LicenseId"
								>
									<v-col cols="2">
										<v-checkbox v-model="license.Selected"></v-checkbox>
									</v-col>
									<v-col cols="3"
										>{{ license.Name }} /
										{{ license.MediaType.TypeName }}</v-col
									>
									<v-col cols="2">{{
										license.DefaultPrice | formatCurrencyAvoidDecimal
									}}</v-col>
									<v-col cols="2">
										{{
											license.ClientDefaultPrice | formatCurrencyAvoidDecimal
										}}
									</v-col>
									<v-col cols="3">
										<v-text-field
											type="number"
											v-model="license.OfferPrice"
											solo
										></v-text-field>
									</v-col>
								</v-row>
							</v-card-text>
							<v-card-actions>
								<v-btn color="grey lighten-2" large @click="currentStep = 1">
									<v-icon left color="info">fas fa-arrow-left</v-icon>
									{{ $t("common.back") }}
								</v-btn>
								<v-spacer></v-spacer>
								<v-btn
									color="primary"
									large
									@click="currentStep = 3"
									:disabled="
										this.CombinedLicensePrices.filter(f => f.Selected)
											.length === 0
									"
								>
									{{ $t("common.continue") }}</v-btn
								>
							</v-card-actions>
						</v-card>
					</v-stepper-content>

					<v-stepper-content step="3">
						<v-card>
							<v-card-text>
								<!-- v-if added for calculating grid layout otherwise it's raw images -->
								<PhotoGrid
									v-if="currentStep === 3"
									:Files="SelectedFiles"
									class="mt-5"
									:targetHeight="200"
								>
									<template v-slot:cell="{ file }">
										<div class="selectableItem rounded">
											<div
												class="selectArea"
												v-if="!formAddEditOffer.FormLoading"
											>
												<v-icon @click="removeSelectedItem(file)" color="white">
													fas fa-times-circle
												</v-icon>
											</div>
											<AzurePreviewImage
												v-if="file.Metadata.PreviewFile"
												:showArtist="true"
												:file="file"
											>
											</AzurePreviewImage>
											<VimeoThumbnail
												v-else-if="file.Metadata.type === 'video'"
												:src="file.Metadata.thumbnail_url"
												:alt="file.Metadata.title"
											>
											</VimeoThumbnail>
										</div>
									</template>
								</PhotoGrid>
								<UpdateCopyrightDialog
									v-model="copyrightDialog.showUpdateCopyrightDialog"
									@ExifUpdated="ExifUpdated"
									:EventId="EventId"
									:SelectedFiles="SelectedFiles"
								>
								</UpdateCopyrightDialog>
							</v-card-text>
							<v-card-actions>
								<v-btn color="grey lighten-2" @click="currentStep = 2">
									<v-icon left color="info">fas fa-arrow-left</v-icon>
									{{ $t("common.back") }}
								</v-btn>
								<v-spacer></v-spacer>
								<v-btn v-if="!isVideoOffer" @click="ShowCopyrightUpdateDialog"
									><v-icon left>fas fa-copyright</v-icon>Modifica
									Copyright</v-btn
								>
								<v-btn
									color="primary"
									:loading="formAddEditOffer.FormLoading"
									@click="SaveOffer"
								>
									{{ $t("saveOfferDraft") }}</v-btn
								>
							</v-card-actions>
						</v-card>
					</v-stepper-content>
				</v-stepper-items>
			</v-stepper>
			<template v-else>
				<AdminOfferCard
					v-if="createdOffer"
					:offer="createdOffer"
					@offerDeleted="OfferUpdated"
					@offerUpdated="OfferUpdated"
				></AdminOfferCard>
			</template>
		</v-card>
	</v-dialog>
</template>
<script>
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";
import CrudClient from "@/services/CrudClient/";
import CrudFormData from "@/utilities/CrudFormData.js";
import metadata from "@/mixins/metadata";
import AdminOfferCard from "./components/AdminOfferCard.vue";
import PhotoGrid from "@/components/Shared/UI/PhotoGrid.vue";
import AzurePreviewImage from "@/components/Shared/UI/AzurePreviewImage.vue";
import Confirm from "@/components/Shared/Common/Confirm";
import { mapActions, mapGetters } from "vuex";
import UpdateCopyrightDialog from "./components/UpdateCopyrightDialog";
import VimeoThumbnail from "@/components/Shared/UI/vimeoThumbnail.vue";

import { SgpOfferTypeEnum } from "./components/sgpOfferTypesEnum.js";
export default {
	name: "CreateOfferDialog",
	$_veeValidate: { validator: "CreateOfferDialog" },
	components: {
		SimpleFormCard,
		AdminOfferCard,
		PhotoGrid,
		AzurePreviewImage,
		Confirm,
		UpdateCopyrightDialog,
		VimeoThumbnail
	},

	mixins: [metadata],
	data() {
		return {
			formIsValid: false,
			currentStep: 1,
			EventDetails: null,

			formAddEditOffer: new CrudFormData("formAddEditOffer", [
				{
					type: "ClientAccountWithTagsPicker",
					FieldName: "ClientUserId"
				},
				{
					FieldName: "Title"
				},
				{
					FieldName: "Description",
					type: "v-textarea",
					"auto-grow": true,
					rows: 2
				},
				{
					FieldName: "DescriptionEN",
					type: "v-textarea",
					"auto-grow": true,
					rows: 2
				},
				{
					FieldName: "EnableThirdPartyApproval",
					type: "v-checkbox"
				}
				// {
				// 	FieldName: "IsCommissionedWork",
				// 	type: "v-checkbox"
				// }
			]),
			LoadingUsers: false,
			LoadingLicenses: false,
			LoadingClientDefaultPrices: false,
			stepperView: true,
			createdOffer: null,

			clientAccounts: [],
			defaultValues: null,
			Licenses: [],
			ClientDefaultPrices: [],
			CombinedLicensePrices: [],
			copyrightDialog: { showUpdateCopyrightDialog: false }
		};
	},
	props: {
		value: { type: Boolean, required: true },
		SelectedFiles: { type: Array, required: true },
		EventId: { type: Number, required: true },
		OfferType: { type: Number, default: 1 },
		isVideoOffer: { type: Boolean, default: false }
	},
	computed: {
		...mapGetters(["SasToken"])
	},
	watch: {
		value: {
			immediate: true,
			handler: async function(value) {
				if (value) {
					this.$log.debug("watch value:" + value);
					this.currentStep = 1;
					this.stepperView = true;
					await this.LoadEventDetails();
					await this.LoadLicenses();
				}
			}
		}
	},
	created() {
		this.OffersService = new CrudClient("SGP/Offers");
		this.SgpEventsService = new CrudClient("SGP/Events");
		this.PhotographyLicensesService = new CrudClient("SGP/PhotographyLicenses");
		this.SgpClientDefaultPricesService = new CrudClient(
			"SGP/ClientDefaultPrices"
		);
		this.UserTagsController = new CrudClient("SGP/UserTags");

		this.SgpOfferTypeEnum = SgpOfferTypeEnum;
	},
	async mounted() {
		this.$log.debug("mounted");
		await this.LoadMetadata(this.OffersService);
		await this.LoadEventDetails();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadEventDetails() {
			this.$log.debug(
				"LoadEventDetails called: IsVideoOffer: " + this.isVideoOffer
			);
			this.EventDetails = await this.SgpEventsService.Get(this.EventId);

			let formattedEventDate = null;
			try {
				// Useful for multi-day events get the date from last sent offer, event date only is not useful
				// Get last offer title from this event
				const lastOfferTitle = await this.SgpEventsService.Get(
					this.EventId,
					"lastoffertitle"
				);

				this.$log.debug(lastOfferTitle);

				if (lastOfferTitle) {
					// parse the title, get date section
					const seperatorIndex = lastOfferTitle.lastIndexOf("|");
					// skip | and following space
					const offerDatePart = lastOfferTitle.substring(seperatorIndex + 2);

					this.$log.debug(offerDatePart);

					formattedEventDate = offerDatePart;
				}
			} catch (error) {
				this.$captureError(error);
			}

			// if not resolved from previous offer, assign date of the event
			if (!formattedEventDate) {
				formattedEventDate = `${this.$options.filters.formatDate(
					this.EventDetails.EventDate
				)}`;
			}

			if (this.isVideoOffer && this.OfferType === this.SgpOfferTypeEnum.OFFER) {
				this.defaultValues = {
					Title: `Video in visione / Video for you: ${this.EventDetails.Name} | ${formattedEventDate}`,
					Description:
						"Abbiamo selezionato un video che riguarda il tuo brand. Per vederlo e acquistarlo clicca il pulsante.",
					DescriptionEN:
						"We have selected a video related to your brand. Click the button to view and purchase it."
				};
			} else if (
				!this.isVideoOffer &&
				this.OfferType === this.SgpOfferTypeEnum.OFFER
			) {
				this.defaultValues = {
					Title: `Immagini in visione / Images for you: ${this.EventDetails.Name} | ${formattedEventDate}`,
					Description:
						"abbiamo selezionato delle immagini per te. Per visualizzarle e acquistarle clicca il pulsante.",
					DescriptionEN:
						"we have selected some images for you. Click the button to view and purchase them."
				};
			} else if (
				!this.isVideoOffer &&
				this.OfferType === this.SgpOfferTypeEnum.APPROVAL
			) {
				this.defaultValues = {
					Title: `Immagini in visione / Images for you: ${this.EventDetails.Name} | ${formattedEventDate}`,
					Description:
						"abbiamo selezionato delle immagini per te. Per visualizzarle e approvarle clicca il pulsante.",
					DescriptionEN:
						"we have selected some images for you. Click the button to view and approve them."
				};
			} else if (
				this.isVideoOffer &&
				this.OfferType === this.SgpOfferTypeEnum.APPROVAL
			) {
				this.defaultValues = {
					Title: `Video in visione / Video for you: ${this.EventDetails.Name} | ${formattedEventDate}`,
					Description:
						"Abbiamo selezionato un video che riguarda il tuo brand. Per visualizzarle e approvarle clicca il pulsante.",
					DescriptionEN:
						"We have selected a video related to your brand. Click the button to view and approve them."
				};
			}
		},

		async Step1Click() {
			if (!(await this.$refs.formAddEditOffer.ValidateForm())) return false;

			const formValues = this.$refs.formAddEditOffer.CopyValues();

			if (formValues.ClientUserId) {
				const clientUserIdWithTagParts = formValues.ClientUserId.split("$");

				const clientUserId = clientUserIdWithTagParts[0];
				// const skillId = clientUserIdWithTagParts[1];

				if (this.OfferType === this.SgpOfferTypeEnum.OFFER) {
					await this.LoadClientDefaultPrices(clientUserId);
					this.currentStep = 2;
				} else {
					this.currentStep = 3;
				}
			}
		},

		async SaveOffer() {
			try {
				this.formAddEditOffer.FormLoading = true;
				const dto = this.$refs.formAddEditOffer.CopyValues();
				dto.EventId = this.EventId;
				dto.OfferType = this.OfferType;

				const clientUserIdWithTagParts = dto.ClientUserId.split("$");

				dto.ClientUserId = clientUserIdWithTagParts[0];
				dto.SkillId = clientUserIdWithTagParts[1];

				const newItem = await this.OffersService.Post(null, dto, null, true);

				// add prices
				for (const file of this.CombinedLicensePrices.filter(f => f.Selected)) {
					await this.OffersService.Post(
						newItem.OfferId,
						{
							LicenseId: file.LicenseId,
							Price: file.OfferPrice
						},
						"offerprice",
						true
					);
				}

				const storagePath = `Events/${this.EventDetails.StorageFolder}/Media/`;

				const itemData = [];

				for (let i = 0; i < this.SelectedFiles.length; i++) {
					const mediaData = {
						MediaTypeId:
							this.SelectedFiles[i].Metadata.type === "video" ? 2 : 1,
						FileName: this.SelectedFiles[i].Path.replace(storagePath, "")
					};

					itemData.push(mediaData);
				}

				await this.OffersService.Post(
					newItem.OfferId,
					itemData,
					"offermediabulk",
					true
				);

				this.snackSuccess({ Text: this.$t("success.draftCreated") });

				this.stepperView = false;
				this.createdOffer = await this.OffersService.GetSelectedFields(
					newItem.OfferId,
					"*, SGPOfferStatus.*, SGPOfferPrices.*, SGPOfferPrices.PhotographyLicense.Name, SGPOfferItems.*, SGPEvent.Name, SGPOrders.*, Skill.Name"
				);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("error.cannotSaveData") });
			} finally {
				this.formAddEditOffer.FormLoading = false;
			}
		},

		async LoadLicenses() {
			const isPhotoOffer = this.SelectedFiles.every(
				item => item.Metadata.PreviewFile !== undefined
			);

			this.$log.debug("isPhotoOffer");
			this.$log.debug(isPhotoOffer);
			const mediaTypeId = isPhotoOffer ? 1 : 2;

			try {
				this.LoadingLicenses = true;

				const res = await this.PhotographyLicensesService.GetPaged({
					skip: 0,
					limit: 0,
					fields: "LicenseId, Name, DefaultPrice, MediaType.*",
					filter: `IsActive:true;MediaTypeId:${mediaTypeId}`
				});

				this.Licenses = res.Data;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingLicenses = false;
			}
		},

		async LoadClientDefaultPrices(userId) {
			try {
				this.LoadingClientDefaultPrices = true;

				// {applicationId}/User/{userId}
				const res = await this.SgpClientDefaultPricesService.GetPaged({
					skip: 0,
					limit: 0,
					fields: "*",
					urlPostFix: "User/" + userId
				});

				this.ClientDefaultPrices = res.Data;

				this.CombinedLicensePrices = [];

				// this.$log.debug(this.ClientDefaultPrices);

				this.Licenses.forEach(licenseItem => {
					const clientDefaultPrice = this.ClientDefaultPrices.find(
						cdp => cdp.LicenseId === licenseItem.LicenseId
					);

					this.CombinedLicensePrices.push({
						...licenseItem,
						ClientDefaultPrice: clientDefaultPrice
							? clientDefaultPrice.Price
							: null,
						OfferPrice:
							(clientDefaultPrice && clientDefaultPrice.Price) ||
							licenseItem.DefaultPrice,
						Selected:
							this.ClientDefaultPrices.length === 0 || !!clientDefaultPrice
					});
				});

				// this.$log.debug(this.ClientDefaultPrices);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingClientDefaultPrices = false;
			}
		},

		closeDialog() {
			this.$emit("input", false);
		},

		OfferUpdated() {
			this.$emit("input", false);
		},

		async removeSelectedItem(file) {
			if (
				!(await this.$refs.confirm.open(
					this.$t("confirmRemoveTitle"),
					this.$t("confirmRemoveText")
				))
			) {
				return;
			}

			file.Selected = false;
		},

		ExifUpdated(item) {
			this.SelectedFiles.find(f => f.Path === item.Path).Metadata =
				item.Metadata;
		},

		ShowCopyrightUpdateDialog() {
			this.copyrightDialog.showUpdateCopyrightDialog = true;
		}
	}
};
</script>
<i18n>
{
	"it":{
		"formAddEditOffer": {
			"Title": "Oggetto",
			"Description": "Messaggio: Gentile Cliente",
			"DescriptionEN": "Messaggio in Inglese: Dear Cliente",
			"ClientUserId": "Cliente",
			"SkillId": "Tag utenti",
			"EnableThirdPartyApproval": "Abilita l'approvazione",
			"IsCommissionedWork": "Nascondi prezzi"
		},
		"SkillIdSelectAll": "Tutti account",
		"LicenseDefaultPrice": "Prezzo Listino",
		"ClientDefaultPrice": "Prezzo Cliente",
		"OfferPrice": "Prezzo Offerta",
		"saveOfferDraft": "Crea Bozza",
		"success.draftCreated": "Bozza creata con successo!",
		"confirmRemoveText": "Vuoi rimuovere l'immagine dal provino?",
		"confirmRemoveTitle": "Rimouvi l'immagine",
		"createOfferTitle": "Crea nuova proposta",
		"createOfferForApprovalTitle": "Crea nuova proposta per approvazione"
	}
}
</i18n>
<style lang="scss" scoped>
.percentagePadding {
	transition: 0.3s;
	padding: 10px;
}

.selectableItem {
	position: relative;
	transition: 0.3s;
}

.selectArea {
	position: absolute;
	z-index: 2;
	top: 10px;
	left: 10px;
}

.copyrightArea {
	position: absolute;
	z-index: 2;
	bottom: 10px;
	left: 10px;
}
</style>
