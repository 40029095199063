<template>
	<div>
		<vue-headful :title="$t('pageTitle')" :description="$t('pageMetaDescription')" />
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
			<v-icon class="secondary--text text--lighten-2">fas fa-caret-right</v-icon>
      </template>
		</v-breadcrumbs>
		<h1 class="text-h3">
			<span v-if="!EventId">{{ $t("pageTitle") }}</span>
			<span v-else-if="eventDetails">Ordini dell'evento {{ eventDetails.Name }}</span>
		</h1>

		<v-row>
			<v-spacer></v-spacer>
			<v-col cols="4" md="2">
				<SgpEventPicker v-model="SelectedEventId" @change="LoadOrders" :disabled="SelectEventDisabled">
				</SgpEventPicker>
			</v-col>
			<v-col cols="4" md="2">
				<ClientAccountWithTagsPicker v-model="SelectedUserId" @change="LoadOrders">
				</ClientAccountWithTagsPicker>
			</v-col>
			<v-col cols="2" md="1" class="text-right">
				<v-btn icon to="./OrdersList" class="pt-8">
					<v-icon>fas fa-list</v-icon></v-btn></v-col>
		</v-row>

		<v-alert dark color="info" v-if="!loadingOrders && Orders.length === 0">{{ $t("NoOrdersFound") }}
		</v-alert>
		<v-progress-linear indeterminate v-if="loadingOrders"></v-progress-linear>
		<div class="text-center  my-3" v-if="itemsPaging">
			<v-pagination :disabled="loadingOrders" v-model="currentPage" :length="itemsPaging.TotalPages"
				:total-visible="5"></v-pagination>
		</div>
		<div ref="offerPagingStart"></div>
		<AdminOrderCard v-for="order in Orders" :key="order.OrderId" :order="order" @fileUploaded="LoadOrders">
		</AdminOrderCard>

		<div class="text-center" v-if="itemsPaging">
			<v-pagination :disabled="loadingOrders" v-model="currentPage" :length="itemsPaging.TotalPages"
				:total-visible="5"></v-pagination>
		</div>
	</div>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import SgpEventPicker from "@/components/Event/SgpEventPicker";
import ClientAccountWithTagsPicker from "@/components/Account/ClientAccountWithTagsPicker.vue";
import AdminOrderCard from "./components/AdminCompactOrderCard.vue";

import { mapActions, mapGetters } from "vuex";

export default {
	name: "AllOrdersList",
	components: {
		AdminOrderCard,
		SgpEventPicker,
		ClientAccountWithTagsPicker,
	},
	data() {
		return {
			Orders: [],
			itemsPaging: null, // TotalItems, Limit, Offset, CurrentPageIndex, TotalPages
			currentPage: 1,
			pageSize: 10,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],

			loadingOrders: false,

			SelectedEventId: null,
			SelectedUserId: null,
			SelectEventDisabled: false,
			eventDetails: null,
		};
	},
	computed: {
		...mapGetters(["SasToken"]),

		EventId() {
			if (!this.$route.params.EventId) return null;
			return parseInt(this.$route.params.EventId);
		},
	},
	watch: {
		async currentPage() {
			await this.LoadOrders();
			this.scrollToElement("offerPagingStart");
		},
	},
	created() {
		this.SgpOrdersService = new CrudClient("SGP/Orders");
		this.SgpEventsService = new CrudClient("SGP/Events");
	},
	async mounted() {
		if (!this.EventId) {
			this.breadcrumbsItems.push({
				text: this.$t("sgp.orders"),
				disabled: false,
				exact: true,
				to: "/Orders",
			});
		}

		if (this.EventId) {
			this.SelectEventDisabled = true;
			this.SelectedEventId = this.EventId;

			this.eventDetails = await this.SgpEventsService.Get(this.EventId);

			this.breadcrumbsItems.push({
				text: this.$t("sgp.events"),
				disabled: false,
				exact: true,
				to: "/Events",
			});
			this.breadcrumbsItems.push({
				text: this.eventDetails.Name,
				disabled: false,
				exact: true,
				to: "/Events/" + this.EventId,
			});
			this.breadcrumbsItems.push({
				text: "Ordini",
				disabled: true,
				exact: true,
				to: "/Events/" + this.EventId + "/Orders",
			});
		}

		await this.LoadOrders();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadOrders() {
			this.$log.debug("Loading Orders");
			let filter = "";
			if (this.SelectedEventId) {
				filter = "EventId:" + this.SelectedEventId;
			}

			if (this.SelectedUserId) {
				const clientUserIdWithTagParts = this.SelectedUserId.split("$");
				const selectedUserId = clientUserIdWithTagParts[0];
				const skillId = clientUserIdWithTagParts[1];

				if (filter !== "") filter += ";";

				filter += "ClientUserId:" + selectedUserId;

				if (skillId) {
					if (filter !== "") filter += ";";
					filter += "SkillId:" + skillId;
				}
			}

			try {
				this.loadingOrders = true;
				const res = await this.SgpOrdersService.GetPaged({
					limit: this.pageSize,
					skip: (this.currentPage - 1) * this.pageSize,
					orderBy: "OrderId:desc",
					filter: filter,
					fields: `*,
					SGPEvent.*,
					SGPOffer.Title, SGPOffer.OfferType,
					SGPOrderItems.*, SGPOrderItems.PhotographyLicense.Name,
					SGPOrderStatus.*, Skill.Name,
					SGPOrderTransactions.*`,
				});

				this.$log.debug(res);
				this.Orders = res.Data;
				this.itemsPaging = res.Paging;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.loadingOrders = false;
			}
		},
		scrollToElement(element, container, enableSmooth = true) {
			if (this.$refs[element]) {
				(container || window).scrollTo({
					top: this.$refs[element].offsetTop,
					left: 0,
					behavior: enableSmooth ? "smooth" : undefined,
				});
			}
		},
	},
};
</script>
<i18n>
{
	"it":{
		"pageTitle": "Ordini",
		"pageMetaDescription": "Tutti ordini",
		"NoOrdersFound": "Nessun ordine trovato"

	}
}
</i18n>
