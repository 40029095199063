<template>
	<v-dialog
		max-width="400"
		:value="value"
		@input="$emit('input', $event)"
		@keydown.esc="CloseDialog"
	>
		<v-card>
			<v-card-title> {{ $t("dialogTitle") }} </v-card-title>
			<v-card-text>
				{{ SelectedFiles.length }} file selezionati
				<v-text-field
					label="Exif ImageDescription"
					v-model="ImageDescription"
				></v-text-field>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="CloseDialog" text color="info" v-if="!updateingExif">{{
					$t("common.cancel")
				}}</v-btn>
				<v-btn
					@click="selectedFilesUpdateExif"
					:loading="updateingExif"
					color="info"
					>{{ $t("common.update") }}</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import api from "@/services/api"
import CrudClient from "@/services/CrudClient/";
import CrudClientV2 from "@/services/CrudClient/index.v2";
import { sleep, asyncPool, Retry, makeid } from "../AsyncPool.js";

export default {
	$_veeValidate: { validator: "CreateUser" },
	data() {
		return {
			updateingExif: false,
			ImageDescription: null,
		};
	},
	props: {
		value: { type: Boolean, required: true },
		EventId: { type: Number, required: true },
		SelectedFiles: { type: Array },
	},
	watch: {
		value: async function (val) {
			this.$log.debug("watch value");
			this.$log.debug(val);

			if (!val) this.CloseDialog();
		},
	},
	created() {
		this.SgpEventsService = new CrudClient("SGP/Events");
		this.ParallelTasksCount = 8;
		this.DelayFirstBatchMs = 500;
	},
	async mounted() {},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		CloseDialog() {
			this.ImageDescription = "";
			this.$emit("input", false);
		},
		async SendUpdateRequest(item, index) {
			const dto = {
				FileName: item.Path,
				ImageDescription: this.ImageDescription,
			};

			if (index < this.ParallelTasksCount) {
				await sleep(index * this.DelayFirstBatchMs);
			}

			const res = await Retry(async () => {
				this.$log.debug("posting with Retry");
				const abortSignal = AbortSignal.timeout(60000);
				const azureFunctionClient = new CrudClientV2("SGP/Events", api.azureFunctionApiUrl);

				return await azureFunctionClient.Post(
					this.EventId,
					dto,
					{
						urlPostFix: "exifImageDescription?makeid=" + makeid(8),
						signal: abortSignal,
						expectReturnData: true
					}
				);
			}, 5000, 3);

			this.$log.debug(res);

			// const res = await azureFunctionClient.Post(
			// 	this.EventId,
			// 	dto,
			// 	"exifImageDescription",
			// 	true
			// );
			this.$emit("ExifUpdated", res);
			return res;
		},

		async selectedFilesUpdateExif() {
			if (this.ImageDescription === "") return;
			try {
				this.updateingExif = true;

				await asyncPool(this.ParallelTasksCount, this.SelectedFiles, (file, i) =>
					this.SendUpdateRequest(file, i)
				);

				this.snackSuccess({ Text: this.$t("File aggiornati") });
				this.CloseDialog();
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.updateingExif = false;
			}
		},
	},
};
</script>

<i18n>
{
    "it": {
		"dialogTitle": "Aggiorna ImageDescription"
    }
}
</i18n>
