<template>
	<v-card class="mb-2">
		<v-container>
			<v-row dense>
				<v-col cols="4">
					<v-img
						class="rounded"
						max-width="150"
						:src="detectedFace.path + SasToken"
					></v-img>
				</v-col>
				<v-col cols="8">
					<v-card
						flat
						v-for="(identifyResult, index) in detectedFace.identifyResults"
						:key="index"
					>
						<v-card-actions
							><v-spacer></v-spacer>
							<v-btn
								@click="$emit('ignoreFace', detectedFace)"
								small
								class="ml-3"
								color="warning"
								icon
								@keydown.prevent="$log.debug('button key down')"
							>
								<v-icon>fas fa-eraser</v-icon>
							</v-btn></v-card-actions
						>
						<v-card-actions
							v-for="candidate in identifyResult"
							:key="candidate.PersonId"
						>
							<FacePersonNameLabel
								:facePersonId="candidate.PersonId"
								:confidence="candidate.Confidence"
							></FacePersonNameLabel>
							<v-icon
								v-if="
									detectedFace.QualityForRecognition !== 'Low' &&
									!(
										originalImage.Metadata['TrainingModelFaces'] &&
										originalImage.Metadata['TrainingModelFaces'].includes(
											detectedFace.FaceId
										)
									)
								"
								class="ml-3"
								color="info"
								@click="addToRecognizedFaceToModel(candidate)"
								>fas fa-plus-circle</v-icon
							>
						</v-card-actions>
					</v-card>

					<v-alert
						type="warning"
						v-if="detectedFace.QualityForRecognition === 'Low'"
					>
						Qualita bassa per riconoscimento
					</v-alert>

					<template
						v-else-if="
							!isPersonRecognized ||
							detectedFace.identifyResults[0][0].Confidence < 0.95
						"
					>
						<v-radio-group v-model="isNewPerson" row>
							<v-radio :value="true" label="Nuova persona"></v-radio>
							<v-radio :value="false" label="Persona esistente"></v-radio>
						</v-radio-group>
						<v-text-field
							v-if="isNewPerson"
							:label="$t('common.name')"
							v-model="facePersonName"
							@keydown.stop
						></v-text-field>
						<SGPFacePersonPicker
							v-else
							v-model="selectedFacePersonId"
							@keydown.stop
						></SGPFacePersonPicker>

						<v-btn @click="addPerson" :loading="addingPerson" color="primary"
							>Add to model</v-btn
						></template
					>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import { mapActions, mapGetters } from "vuex";
import SGPFacePersonPicker from "./SGPFacePersonPicker";
import FacePersonNameLabel from "./components/FacePersonNameLabel.vue";

export default {
	components: { SGPFacePersonPicker, FacePersonNameLabel },
	data() {
		return {
			DetectingFaces: false,
			isNewPerson: true,
			facePersonName: null,
			LoadingData: false,
			selectedFacePersonId: null,
			addingPerson: false,
		};
	},

	props: {
		detectedFace: { type: Object },
		eventId: { type: Number },
		originalImage: { type: Object },
	},
	computed: {
		...mapGetters(["SasToken"]),

		isPersonRecognized() {
			return (
				this.detectedFace.identifyResults[0] &&
				this.detectedFace.identifyResults[0][0]
			);
		},
	},
	created() {
		this.FacePersonService = new CrudClient("Face/Person");
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async addPerson() {
			try {
				this.addingPerson = true;
				let facePersonId = this.selectedFacePersonId;
				if (this.isNewPerson) {
					if (!this.facePersonName) return;

					// check if exists
					var result = await this.FacePersonService.GetPaged({
						limit: 10,
						skip: 0,
						fields: "*",
						filter: `Name:${this.facePersonName}`,
					});

					if (result.Data.length > 0) {
						this.snackError({ Text: "Persona gia' esiste" });
						return;
					}

					// Create FacePerson
					const dto = { Name: this.facePersonName };
					facePersonId = await this.FacePersonService.Post(null, dto, null, true);

					this.facePersonName = null;
				}

				const dto = {
					EventId: this.eventId,
					FaceId: this.detectedFace.FaceId,
					OriginalImagePath: this.originalImage.Path,
				};

				// add detected face to the FacePerson
				const updatedBlob = await this.FacePersonService.Post(
					facePersonId,
					dto,
					"addfacetomodel",
					true
				);

				if (updatedBlob.Exceptions) {
					this.$captureError(updatedBlob);
					this.snackError({
						Title: updatedBlob.Message,
						Text: updatedBlob.Exceptions.join(" "),
					});

					return;
				}

				// add train re-run
				await this.FacePersonService.Post(null, null, "Train");
				this.$emit("faceAdded", updatedBlob);

				this.snackSuccess({ Text: this.$t("common.success.addItem") });
			} catch (error) {
				this.snackError("common.error.cannotAddItem");
				this.$captureError(error);
			} finally {
				this.addingPerson = false;
			}
		},

		async addToRecognizedFaceToModel(candiate) {
			try {
				this.$log.debug(candiate);

				this.addingPerson = true;

				const res = await this.FacePersonService.GetPaged({
					limit: 1,
					skip: 0,
					fields: "FacePersonId",
					filter: `PersonGroupPersonId:${candiate.PersonId}`,
				});

				let FacePersonId;

				if (res.Data.length > 0) FacePersonId = res.Data[0].FacePersonId;

				const dto = {
					EventId: this.eventId,
					FaceId: this.detectedFace.FaceId,
					OriginalImagePath: this.originalImage.Path,
				};

				// add detected face to the FacePerson
				const updatedBlob = await this.FacePersonService.Post(
					FacePersonId,
					dto,
					"addfacetomodel",
					true
				);

				if (updatedBlob.Exceptions) {
					this.$captureError(updatedBlob);
					this.snackError({
						Title: updatedBlob.Message,
						Text: updatedBlob.Exceptions.join(" "),
					});

					return;
				}

				this.$emit("faceAddedToModel", updatedBlob);
				// this.$log.debug("facePersonImageId: " + facePersonImageId);

				this.snackSuccess({ Text: this.$t("common.success.addItem") });
			} catch (error) {
				this.snackError("common.error.cannotAddItem");
				this.$captureError(error);
			} finally {
				this.addingPerson = false;
			}
		},
	},
};
</script>
