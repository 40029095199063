<template>
	<v-icon :color="color" v-bind="$attrs" v-on="$listeners">{{ icon }}</v-icon>
</template>
<script>
export default {
	computed: {
		icon() {
			switch (this.eventName) {
				case "dropped":
				case "bounce":
				case "report":
				case "spam":
					return "fas fa-exclamation-triangle";
				case "deferred":
					return "fas fa-question-circle";
				case null:
				case "processed":
					// <i class="fas fa-hourglass-start"></i>
					return "fas fa-hourglass-start";

				case "open":
				case "delivered":
				case "click":
					return "fas fa-check-circle";
			}

			return "fas fa-question-circle";
		},

		color() {
			switch (this.eventName) {
				case null:
					return "grey";
				case "dropped":
				case "bounce":
				case "report":
				case "spam":
					return "error";
				case "deferred":
					return "warning";

				case "open":
				case "delivered":
				case "click":
					return "success";
				case "processed":
					return "grey";
			}

			return "grey";
		},
	},
	props: {
		eventName: { type: String },
		// addEmptyItem: { type: Boolean, required: false, default: false },
	},
};
</script>
