var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-img',{staticClass:"grey darken-4 rounded",attrs:{"src":_vm.file.Metadata.PreviewFile + _vm.SasToken,"alt":_vm.file.DisplayName,"aspect-ratio":_vm.file.Metadata.AspectRatio},on:{"click":function($event){return _vm.$emit('click')}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-2"}})],1)]},proxy:true}])},[(_vm.showArtist && _vm.file.Metadata && _vm.file.Metadata.Artist)?_c('div',{staticClass:"ArtistInfo"},[_vm._v(" "+_vm._s(_vm.file.Metadata.Artist)+" ")]):_vm._e(),(
			_vm.showImageDescription && _vm.file.Metadata && _vm.file.Metadata.ImageDescription
		)?_c('div',{staticClass:"ImageDescription"},[_vm._v(" "+_vm._s(_vm.file.Metadata.ImageDescription)+" ")]):_vm._e(),(_vm.showImageDescription && _vm.file.Metadata)?_c('div',{staticClass:"FaceRecognitionIcon"},[(_vm.file.Metadata['manuelExif'] && _vm.file.Metadata['manuelExif'] === 'true')?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("fas fa-pen-square")]):[(_vm.file.Metadata.DetectedFaces === undefined)?_c('v-icon',{attrs:{"color":"warning lighten-1"}},[_vm._v("fas fa-question-circle")]):_vm._e(),(parseInt(_vm.file.Metadata.DetectedFaces) === 0)?_c('v-icon',{attrs:{"color":"deep-purple darken-1"}},[_vm._v("fas fa-empty-set")]):_vm._e(),(
					_vm.file.Metadata.DetectedFaces === _vm.file.Metadata.IdentifiedFaces &&
					_vm.file.Metadata.HasLowConfidenceResult !== 'true' &&
					parseInt(_vm.file.Metadata.IdentifiedFaces) > 0
				)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("fas fa-check-double")]):_vm._e(),(
					_vm.file.Metadata.DetectedFaces !== _vm.file.Metadata.IdentifiedFaces ||
					_vm.file.Metadata.HasLowConfidenceResult === 'true'
				)?_c('span',[_c('span',{staticClass:"infoText"},[_vm._v(_vm._s(_vm.file.Metadata.DetectedFaces)+" / "+_vm._s(_vm.file.Metadata.IdentifiedFaces))]),_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("fas fa-exclamation-triangle")])],1):_vm._e()]],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }