<template>
	<span>
		<v-progress-linear indeterminate v-if="Loading"></v-progress-linear>
		<v-chip v-else :color="labelColor" dark label>
			{{ this.Name }}
		</v-chip>
	</span>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";

export default {
	props: {
		facePersonId: { type: String, default: null },
		confidence: { type: Number },
	},
	data() {
		return { Name: null, Loading: false };
	},
	computed: {
		labelColor() {
			if (this.confidence > 0.95) {
				return "green darken-4";
			}

			if (this.confidence > 0.9) {
				return "green darken-3";
			}

			if (this.confidence > 0.85) {
				return "green darken-2";
			}

			if (this.confidence > 0.8) {
				return "green darken-1";
			}

			if (this.confidence > 0.75) {
				return "green lighten-1";
			}
			if (this.confidence > 0.7) {
				return "green lighten-2";
			}

			return "grey darken-2";
		},
	},
	watch: {
		facePersonId: {
			immediate: true,
			handler: async function (newVal) {
				if (newVal) {
					await this.GetFacePerson();
				} else {
					this.Name = null;
				}
			},
		},
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async GetFacePerson() {
			try {
				this.Loading = true;

				const FacePersonService = new CrudClient("Face/Person");
				const res = await FacePersonService.GetPaged({
					limit: 1,
					skip: 0,
					fields: "Name",
					filter: `PersonGroupPersonId:${this.facePersonId}`,
				});
				// this.$log.debug(res);
				if (res.Data.length > 0) this.Name = res.Data[0].Name;
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.Loading = false;
			}
		},
	},
};
</script>
