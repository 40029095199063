<template>
	<v-card class="ma-2 pa-2">
		<confirm ref="confirm"></confirm>

		<v-card flat :to="'/Offers/' + offer.OfferId">
			<v-card-text>
				<v-row dense>
					<v-col cols="12" md="8">
						<div class="font-weight-bold" style="font-size: 18px">
							{{ offer.Title }}
						</div>
						<div class="text-overline">
							{{ $t("sgp.offer") }} {{ offer.OfferId }}
						</div>

						<span class="font-weight-bold pr-1">Evento:</span>
						{{ offer.SGPEvent.Name }}
						<br />
						<span class="font-weight-bold pr-1">Cliente:</span>
						<UserPublicProfileName
							:UserId="offer.ClientUserId"
						></UserPublicProfileName>
						<v-chip
							v-if="offer.SkillId"
							color="orange darken-3"
							class="ml-3"
							dark
							small
						>
							<v-icon left x-small>fas fa-tag </v-icon>
							{{ offer.Skill.Name }}</v-chip
						>

						<v-chip
							v-if="offer.OfferType === SgpOfferTypeEnum.APPROVAL"
							color="purple"
							class="ml-3"
							dark
							small
						>
							<v-icon left x-small>fas fa-shield-check </v-icon>
							{{ $t("sgp.offerForApproval") }}</v-chip
						>

						<v-chip
							v-if="
								offer.OfferType === SgpOfferTypeEnum.OFFER &&
									offer.EnableThirdPartyApproval
							"
							color="indigo"
							class="ml-3"
							dark
							small
						>
							<v-icon left x-small>fas fa-shield-check </v-icon>
							Con Link di Approvazione</v-chip
						>

						<br />
						<span class="font-weight-bold pr-1">Stati Email:</span>
						<MailEventIcon
							v-for="ms in mailStatus"
							:key="ms"
							size="15"
							:eventName="ms"
							class="mr-2"
						></MailEventIcon>

						<template v-if="offer.SGPOrders.length > 0">
							<div class="mt-2">
								<span class="font-weight-bold pr-1">Ordini:</span>
								<span v-for="order in offer.SGPOrders" :key="order.OrderId">
									<v-chip
										small
										class="blue darken-2 mr-3"
										dark
										:to="'/Orders/' + order.OrderId"
										>Ordine {{ order.OrderId }}</v-chip
									>
								</span>
							</div>
						</template>
					</v-col>
					<v-col cols="12" md="4">
						<PhotoGrid :Files="offerMedia" class="mt-3" :targetHeight="100">
							<template v-slot:cell="{ file }">
								<OfferImage v-if="file.MediaTypeId === 1" :file="file">
								</OfferImage>
								<VimeoThumbnail
									v-else-if="file.MediaTypeId === 2"
									:src="file.ExternalPreviewUrl"
									:alt="file.FileName"
								></VimeoThumbnail>
								<v-responsive
									:aspect-ratio="file.AspectRatio"
									v-else
									class="grey lighten-2 rounded"
								>
									<div class="fill-height d-flex">
										<div
											class="
												align-self-center
												text-center text-weight-bold
												blue-grey--text
												text--darken-2
											"
											style="font-size: 32px; width: 100%"
										>
											{{ file.Text }}
										</div>
									</div>
								</v-responsive>
							</template>
						</PhotoGrid>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		<v-card-actions>
			<v-chip>{{ offer.SGPOfferStatus.Name }}</v-chip>
			<template v-if="offer.StatusId === SgpOfferStatusIdEnum.SENT">
				<span class="ml-2 grey--text text-body-2">
					{{ offer.CreateDate | formatLocalDateTime }}</span
				>
			</template>
			<v-spacer></v-spacer>
			<template v-if="offer.StatusId === SgpOfferStatusIdEnum.DRAFT">
				<v-btn
					color="warning"
					text
					:disabled="sendingOffer"
					:loading="deletingOffer"
					@click="deleteOffer(offer.OfferId)"
					>{{ $t("button.discardDraft") }}</v-btn
				>
				<v-btn
					@click="sendOffer(offer.OfferId)"
					:loading="sendingOffer"
					color="primary"
					>{{ $t("button.sendOffer") }}</v-btn
				>
			</template>
			<template v-if="offer.StatusId === SgpOfferStatusIdEnum.SENT">
				<v-btn
					@click="revokeOffer(offer.OfferId)"
					:loading="revokingOffer"
					text
					color="error"
					>{{ $t("button.revokeOffer") }}</v-btn
				>
			</template>
		</v-card-actions>
	</v-card>
</template>
<script>
import Confirm from "@/components/Shared/Common/Confirm";
import UserPublicProfileName from "@/components/Shared/UI/UserPublicProfileName";
import CrudClient from "@/services/CrudClient/";

import PhotoGrid from "@/components/Shared/UI/PhotoGrid.vue";
import VimeoThumbnail from "@/components/Shared/UI/vimeoThumbnail.vue";
import OfferImage from "@/components/Shared/UI/OfferImage.vue";
import MailEventIcon from "../MailEventIcon.vue";
import { SgpOfferTypeEnum } from "./sgpOfferTypesEnum.js";
import { SgpOfferStatusIdEnum } from "./sgpOfferStatus";
import { mapActions, mapGetters } from "vuex";

export default {
	name: "AdminOfferCard",
	components: {
		Confirm,
		UserPublicProfileName,
		VimeoThumbnail,
		PhotoGrid,
		OfferImage,
		MailEventIcon
	},
	data() {
		return {
			deletingOffer: false,
			sendingOffer: false,
			revokingOffer: false
		};
	},
	props: {
		offer: {
			type: Object,
			required: true
		}
	},
	computed: {
		...mapGetters(["SasToken"]),

		offerMedia() {
			const ImagesInArray = 4;
			let array;
			// for n+1 images, there is no need to specify +1
			if (this.offer.SGPOfferItems.length === ImagesInArray + 1) {
				array = this.offer.SGPOfferItems.slice(0, ImagesInArray + 1);
				return array;
			}

			array = this.offer.SGPOfferItems.slice(0, ImagesInArray);
			if (this.offer.SGPOfferItems.length > ImagesInArray) {
				array.push({
					AspectRatio: 0.66,
					FileName: null,
					MediaTypeId: -1,
					Text: "+" + (this.offer.SGPOfferItems.length - ImagesInArray)
				});
			}

			return array;
		},

		mailStatus() {
			return this.offer.SGPOfferMails.map(m => m.SentEmail.LastEvent);
		}
	},
	created() {
		this.OffersService = new CrudClient("SGP/Offers");
		this.SgpOfferTypeEnum = SgpOfferTypeEnum;
		this.SgpOfferStatusIdEnum = SgpOfferStatusIdEnum;
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async deleteOffer(offerId) {
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					this.$t("common.confirmDelete")
				))
			) {
				return;
			}

			try {
				this.deletingOffer = true;
				await this.OffersService.Delete(offerId);
				this.snackSuccess({ Text: this.$t("offerDeleted") });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotDeleteItem") });
			} finally {
				this.deletingOffer = false;
				this.$emit("offerDeleted");
			}
		},

		async sendOffer(offerId) {
			if (
				!(await this.$refs.confirm.open(
					this.$t("confirmSendTitle"),
					this.$t("confirmSendText")
				))
			) {
				return;
			}

			try {
				this.sendingOffer = true;
				await this.OffersService.Post(offerId, null, "sendoffer");

				this.snackSuccess({ Text: this.$t("offerSent") });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("cannotSendOffer") });
			} finally {
				this.sendingOffer = false;
				this.$emit("offerUpdated");
			}
		},

		async revokeOffer(offerId) {
			if (
				!(await this.$refs.confirm.open(
					this.$t("confirmRevokeTitle"),
					this.$t("confirmRevokeText")
				))
			) {
				return;
			}

			try {
				this.revokingOffer = true;
				await this.OffersService.Post(offerId, null, "revoke");

				this.snackSuccess({ Text: this.$t("offerRevoked") });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("cannotRevokeOffer") });
			} finally {
				this.revokingOffer = false;
				this.$emit("offerUpdated");
			}
		}
	}
};
</script>
<i18n>
{"it":{
	"button.discardDraft": "Elimina bozza",
	"button.sendOffer": "Invia Proposta",
	"button.revokeOffer": "Revoca",
	"offerDeleted": "Proposta cancellata",
	"cannotSendOffer": "Errore durante l'invio  della proposta",
	"offerSent": "La proposta inviata!",
	"confirmSendTitle": "Confermo invio",
	"confirmSendText": "Sei sicuro di voler inviare la proposta?",
	"offerSent": "Proposta inviata con successo",

	"cannotRevokeOffer": "Errore durante la revoca  della proposta",
	"offerRevoked": "La proposta revocata!",
	"confirmRevokeTitle": "Confermo revoca",
	"confirmRevokeText": "Sei sicuro di voler revocare la proposta?",
	"offerRevoked": "Proposta revocata con successo"
}}
</i18n>
