<template>
	<v-card class="mb-3">
		<confirm ref="confirm"></confirm>

		<v-row dense>
			<v-col cols="12" md="8">
				<v-card flat :to="'/Orders/' + order.OrderId">
					<v-card-text>
						<span class="text-overline"
							>{{ $t("sgp.orderId") }} {{ order.OrderId }}
						</span>
						<sgpOrderStatusChip :StatusId="order.StatusId"></sgpOrderStatusChip>

						<v-chip color="info" class="ml-3" v-if="!order.OfferId">{{
							$t("sgp.directDeliveryOrder")
						}}</v-chip>
						<template v-else>
							<v-chip
								v-if="order.SGPOffer.OfferType === SgpOfferTypeEnum.APPROVAL"
								:to="'/Offers/' + order.OfferId"
								color="purple"
								class="ml-3"
								dark
							>
								<v-icon left x-small>fas fa-shield-check </v-icon>
								{{ $t("sgp.offerForApproval") }} {{ order.OfferId }}</v-chip
							>
							<v-chip
								color="info"
								class="ml-3"
								:to="'/Offers/' + order.OfferId"
								v-else
							>
								dalla {{ $t("sgp.offer") }} {{ order.OfferId }}
							</v-chip>
						</template>
						<!-- Not apporval order and ExternalInvoiceId is empty -->
						<template
							v-if="
								!(
									order.OfferId && order.SGPOffer.OfferType === SgpOfferTypeEnum.APPROVAL
								)
							"
						>
							<v-chip v-if="!order.ExternalInvoiceId" color="warning" class="ml-2"
								>da fatturare</v-chip
							>
							<v-chip v-else color="green darken-1" dark class="ml-2"
								>Id Consegna: {{ order.ExternalInvoiceId }}
							</v-chip>
						</template>
						<template v-if="order.SGPOrderTransactions.length > 0">
							<v-icon color="primary" class="ml-3">fab fa-paypal</v-icon></template
						>
						<br />
						<span class="font-weight-bold pr-1">{{ $t("sgp.event") }}:</span>
						{{ order.SGPEvent.Name }} del
						{{ (order.ExactEventDate || order.SGPEvent.EventDate) | formatDate }}
						<br />
						<span class="font-weight-bold pr-1">{{ $t("sgp.client") }}: </span>
						<UserPublicProfileName
							:UserId="order.ClientUserId"
						></UserPublicProfileName>
						<v-chip
							v-if="order.SkillId"
							color="orange darken-3"
							class="ml-3"
							dark
							small
						>
							<v-icon left x-small>fas fa-tag </v-icon>
							{{ order.Skill.Name }}</v-chip
						>

						/
						<UserPublicProfileName
							v-if="order.CreatedBy && order.CreatedBy !== order.ClientUserId"
							:UserId="order.CreatedBy"
						></UserPublicProfileName>

						<br />
						<span class="font-weight-bold pr-1">{{ $t("orderDate") }}:</span>
						{{ order.CreateDate | formatDate }}
						<br />

						<template
							v-if="
								order.SGPOrderItems.length > 0 &&
								order.SGPOrderItems[0].PhotographyLicense
							"
						>
							<span class="font-weight-bold pr-1">{{ $t("sgp.license") }}:</span>
							{{ order.SGPOrderItems[0].PhotographyLicense.Name }}

							<br />
							<span class="font-weight-bold pr-1">{{ $t("common.expiration") }}:</span>
							{{ order.SGPOrderItems[0].LicenseExpireDate | formatDate }}

							<br />
							<template v-if="order.CalculateTotalByItems">
								<span class="font-weight-bold pr-1">{{ $t("common.price") }}:</span>
								{{ order.SGPOrderItems[0].Price | formatCurrency }} x
								{{ order.SGPOrderItems.length }}
								<br />
							</template>
						</template>

						<span class="font-weight-bold pr-1">{{ $t("common.total") }}:</span>
						{{ order.Total | formatCurrency }} {{ $t("sgp.plusVAT") }}
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" md="4">
				<v-row v-if="order.StatusId !== 3" dense>
					<v-col>
						<PhotoGrid :Files="orderMedia" class="mt-5" :targetHeight="100">
							<template v-slot:cell="{ file }">
								<OrderImage v-if="file.MediaTypeId === 1" :file="file">
									<div class="pa-3 black white--text" style="opacity: 0.6">
										{{ file.FileName }}
									</div>
								</OrderImage>
								<VimeoThumbnail
									v-else-if="file.MediaTypeId === 2"
									:src="file.ExternalPreviewUrl"
									:alt="file.FileName"
								></VimeoThumbnail>
								<v-responsive
									:aspect-ratio="file.AspectRatio"
									v-else
									class="grey lighten-2 rounded"
								>
									<div class="fill-height d-flex">
										<div
											class="
												align-self-center
												text-center text-weight-bold
												blue-grey--text
												text--darken-2
											"
											style="font-size: 32px; width: 100%"
										>
											{{ file.Text }}
										</div>
									</div>
								</v-responsive>
							</template>
						</PhotoGrid>
					</v-col>
				</v-row>
				<v-row dense v-else>
					<v-col cols="12">
						<v-alert
							border="left"
							colored-border
							color="yellow darken-3"
							elevation="2"
							icon="fas fa-sticky-note"
						>
							<span class="font-weight-bold"> Richiesta: </span>
							<span style="white-space: pre-wrap">
								{{ order.RetouchingNotes }}</span
							></v-alert
						>
					</v-col>
					<v-col
						cols="6"
						md="3"
						v-for="sgpOrderItem in order.SGPOrderItems"
						:key="sgpOrderItem.MediaId"
					>
						<RetouchingCard
							:sgpOrderItem="sgpOrderItem"
							@fileUploaded="$emit('fileUploaded', $event)"
						></RetouchingCard>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn
				color="info"
				v-if="order.ZipCreated"
				text
				:href="order.ZipSrc + SasToken"
				target="_blank"
				><v-icon> left fas fa-cloud-download</v-icon>Scarica tutte le foto</v-btn
			>

			<v-btn
				color="info"
				v-if="
					order.StatusId === 3 &&
					order.SGPOrderItems.filter((oi) => !oi.RetouchedUploaded).length === 0
				"
				:loading="SendingOrder"
				@click="DeliverPostProduction(order.OrderId)"
				>{{ $t("deliverOrder") }}</v-btn
			>
		</v-card-actions>
	</v-card>
</template>
<script>
import Confirm from "@/components/Shared/Common/Confirm";
import PhotoGrid from "@/components/Shared/UI/PhotoGrid.vue";
import VimeoThumbnail from "@/components/Shared/UI/vimeoThumbnail.vue";
import OrderImage from "@/components/Shared/UI/OrderImage.vue";
import UserPublicProfileName from "@/components/Shared/UI/UserPublicProfileName";

import RetouchingCard from "../RetouchingCard.vue";
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";

import sgpOrderStatusChip from "./sgpOrderStatusChip.vue";
import { SgpOfferTypeEnum } from "./sgpOfferTypesEnum.js";

export default {
	components: {
		PhotoGrid,
		OrderImage,
		VimeoThumbnail,
		UserPublicProfileName,
		RetouchingCard,
		Confirm,
		sgpOrderStatusChip,
	},
	props: {
		order: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			SendingOrder: false,
		};
	},
	computed: {
		...mapGetters(["SasToken"]),

		orderMedia() {
			const ImagesInArray = 4;
			let array;
			// for n+1 images, there is no need to specify +1
			if (this.order.SGPOrderItems.length === ImagesInArray + 1) {
				array = this.order.SGPOrderItems.slice(0, ImagesInArray + 1);
				return array;
			}

			array = this.order.SGPOrderItems.slice(0, ImagesInArray);
			if (this.order.SGPOrderItems.length > ImagesInArray) {
				array.push({
					AspectRatio: 0.66,
					FileName: null,
					MediaTypeId: -1,
					Text: "+" + (this.order.SGPOrderItems.length - ImagesInArray),
				});
			}

			return array;
		},
	},
	created() {
		this.SgpOrdersService = new CrudClient("SGP/Orders");
		this.SgpOfferTypeEnum = SgpOfferTypeEnum;
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),
		async DeliverPostProduction(orderId) {
			if (
				!(await this.$refs.confirm.open(
					this.$t("confirmDeliverOrderTitle"),
					this.$t("confirmDeliverOrderText")
				))
			) {
				return;
			}
			try {
				this.SendingOrder = true;
				// deliver the order
				await this.SgpOrdersService.Post(
					orderId,
					null,
					"DeliverPostProduction",
					false
				);

				this.snackSuccess({ Text: this.$t("orderSent") });

				// TODO: zip not required for approval orders

				// create order zip without waiting...
				this.SgpOrdersService.Post(orderId, null, "zip", false);

				this.$emit("fileUploaded", null);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("error.cannotSaveData") });
			} finally {
				this.SendingOrder = false;
			}
		},
	},
};
</script>
<i18n>
{
	"it":{
		"orderDate": "Data ordine",
		"deliverOrder": "Consegna ritocchi",
		"confirmDeliverOrderTitle": "Consegna ritocchi",
		"confirmDeliverOrderText": "Sei sicuro di voler consegnare l'ordine?",
		"orderSent": "Ordine inviato al cliente"
	}
}
</i18n>
