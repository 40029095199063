<template>
	<v-row>
		<v-col cols="6" sm="4" md="3" lg="2" class="pa-1" v-for="file in uploadQueue" :key="file.url">
			<v-img class="darkened-image rounded" :src="file.url" :alt="file.name" aspect-ratio="1.2">
				<v-row class="fill-height ma-0" align="center" justify="center">
					<v-col class="px-2 py-4 grey lighten-1">
						<v-progress-linear indeterminate></v-progress-linear>
					</v-col>
				</v-row>
			</v-img>
		</v-col>
	</v-row>
</template>

<script>
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";
import { QueueStatus } from "./queueStatusEnum.js";

export default {
	props: {
		uploadQueue: { type: Array, required: true },
		currentSubFolder: { type: String },
		EventId: { type: Number },
	},
	created() {
		this.SgpEventsService = new CrudClient("SGP/Events");
		this.QueueStatus = QueueStatus;
	},
	watch: {
		uploadQueue: {
			immediate: true,
			handler: async function (queue) {
				if (queue.length > 0) {
					await this.UploadFilesFromQueue();
				}
			},
		},
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async UploadFilesFromQueue() {
			// Original method
			// while (this.uploadQueue.length > 0) {
			// 	const file = this.uploadQueue[0];

			// 	this.UploadFile(file);

			// 	this.uploadQueue.shift();
			// }

			const parallelUploads = 3;

			// items already uploading
			const processingLength = this.uploadQueue.filter(
				(e) => e.uploadStatus === this.QueueStatus.UPLOADING
			).length;
			let i = processingLength;

			// There are already at least as many files uploading than should be
			if (processingLength >= parallelUploads) {
				return;
			}

			const queuedFiles = this.QueuedFiles().length;

			if (queuedFiles === 0) {
				return;
			}

			while (i < parallelUploads) {
				if (i > 1) {
					this.$log.debug("waiting 1.5 second");
					await new Promise((resolve) => setTimeout(resolve, 1500));
				}
				if (!this.QueuedFiles().length) {
					return;
				} // Nothing left to process

				const currentFile = this.QueuedFiles()[0];
				currentFile.uploadStatus = this.QueueStatus.UPLOADING;

				this.UploadFile(currentFile);
				i++;
			}
		},

		QueuedFiles() {
			return this.uploadQueue.filter(
				(e) => e.uploadStatus === this.QueueStatus.QUEUED
			);
		},

		async UploadFile(file) {
			this.$log.debug("uploading: " + file.name);

			let parameters = "isEditedVersion=false";
			if (this.currentSubFolder) {
				parameters += "&subFolder=" + encodeURIComponent(this.currentSubFolder);
			}

			let blobFile;
			try {
				blobFile = await this.SgpEventsService.UpdateImage(
					this.EventId,
					file.name,
					file,
					"postimage?" + parameters
				);
				this.$log.debug(file.name + " uploaded");

				// insert to the Event files
				blobFile[0].Selected = false;
				this.$emit("fileUploaded", blobFile[0]);
				// this.Files.push(blobFile[0]);
			} catch {
				this.snackError({ Text: "Impossibile caricare " + file.name });
			} finally {
				// remove uploaded item from queue
				const index = this.uploadQueue.findIndex((f) => f.name === file.name);
				this.uploadQueue.splice(index, 1);
			}

			this.$nextTick(async () => {
				await this.UploadFilesFromQueue();
			});
		},
	},
};
</script>
