<template>
	<v-card class="ma-2 pa-2">
		<confirm ref="confirm"></confirm>

		<v-card-title>{{ offer.Title }}</v-card-title>
		<v-card-text>
			{{ offer.Description }}
			<v-divider class="my-3"></v-divider>

			<v-alert type="info" v-if="offer.EnableThirdPartyApproval">
				<v-text-field
					:value="this.ApproverUrl"
					readonly
					append-icon="fas fa-copy"
					@click:append="CopyApproverUrl"
					label="Link per Approvazione"
				></v-text-field>
			</v-alert>

			<v-row dense>
				<v-col cols="12" md="8"
					><span class="text-overline">
						{{ $t("sgp.offer") }} {{ offer.OfferId }}</span
					>
					<br />
					<span class="font-weight-bold pr-1">Evento:</span>
					{{ offer.SGPEvent.Name }}
					<br />
					<span class="font-weight-bold pr-1">Cliente:</span>
					<UserPublicProfileName
						:UserId="offer.ClientUserId"
					></UserPublicProfileName>
					<v-chip
						v-if="offer.SkillId"
						color="orange darken-3"
						class="ml-3"
						dark
						small
					>
						<v-icon left x-small>fas fa-tag </v-icon>
						{{ offer.Skill.Name }}</v-chip
					>

					<v-chip
						v-if="offer.OfferType === SgpOfferTypeEnum.APPROVAL"
						color="purple"
						class="ml-3"
						dark
						small
					>
						<v-icon left x-small>fas fa-shield-check </v-icon>
						{{ $t("sgp.offerForApproval") }}</v-chip
					>

					<v-chip
						v-if="
							offer.OfferType === SgpOfferTypeEnum.OFFER &&
								offer.EnableThirdPartyApproval
						"
						color="indigo"
						class="ml-3"
						dark
						small
					>
						<v-icon left x-small>fas fa-shield-check </v-icon>
						Con Link di Approvazione</v-chip
					>

					<template v-if="offer.SGPOrders.length > 0">
						<div class="mt-2">
							<span class="font-weight-bold pr-1">Ordini:</span>
							<span v-for="order in offer.SGPOrders" :key="order.OrderId">
								<v-chip
									small
									class="blue darken-2 mr-3"
									dark
									:to="'/Orders/' + order.OrderId"
									>Ordine {{ order.OrderId }}</v-chip
								>
							</span>
						</div>
					</template>
				</v-col>
				<v-col cols="12" md="4">
					<div
						style="max-width: 300px"
						v-if="offer.OfferType === SgpOfferTypeEnum.OFFER"
					>
						<v-container>
							<v-row dense class="blue darken-2 white--text">
								<v-col cols="8" class="px-2">Licenza</v-col>
								<v-col cols="4" class="px-2">Prezzo</v-col>
							</v-row>
							<v-row
								class="blue lighten-4"
								dense
								v-for="offerprice in offer.SGPOfferPrices"
								:key="offerprice.LicenseId"
							>
								<v-col cols="8" class="px-2">
									{{ offerprice.PhotographyLicense.Name }}</v-col
								>
								<v-col cols="4" class="px-2">{{
									offerprice.Price | formatCurrency
								}}</v-col>
							</v-row>
						</v-container>
					</div>

					<div v-if="offer.StatusId === SgpOfferStatusIdEnum.SENT">
						<v-container style="font-size: 12px">
							<v-row dense class="blue darken-2 white--text">
								<v-col cols="6">Email</v-col>
								<v-col cols="3">Stato</v-col>
								<v-col cols="3">Ultimo agg.</v-col>
							</v-row>
							<v-row
								dense
								v-for="offerMail in offer.SGPOfferMails"
								:key="offerMail.OfferMailId"
							>
								<template v-if="offerMail.SentEmail">
									<v-col cols="6"
										>{{ offerMail.SentEmail.EmailAddress }}

										<v-icon
											small
											color="info"
											@click="
												ShowQuickAccessDialog(
													offer.OfferId,
													offerMail.SentEmail.EmailAddress
												)
											"
											>fas fa-link</v-icon
										>
									</v-col>

									<v-col cols="3"
										>{{ offerMail.SentEmail.LastEvent }}
										<MailEventIcon
											size="15"
											:eventName="offerMail.SentEmail.LastEvent"
										></MailEventIcon>
									</v-col>
									<v-col cols="3">{{
										offerMail.SentEmail.LastUpdateTime | formatDateTime
									}}</v-col>
								</template>
							</v-row>
						</v-container>
					</div>
				</v-col>
			</v-row>

			<PhotoGrid :Files="offer.SGPOfferItems" class="mt-5" :targetHeight="250">
				<template v-slot:cell="{ file }">
					<OfferImage v-if="file.MediaTypeId === 1" :file="file">
						<div class="ArtistInfo" v-if="file.ArtistName">
							{{ file.ArtistName }}
						</div>
						<div class="ApprovalInfo" v-if="file.SgpApprovalOfferItem">
							<ApproveStatusIcon
								:size="18"
								:approve-status="file.SgpApprovalOfferItem.ApprovalStatus"
							></ApproveStatusIcon>

							{{ file.SgpApprovalOfferItem.RetouchingNotes }}
						</div>
					</OfferImage>
					<VimeoThumbnail
						v-else
						:src="file.ExternalPreviewUrl"
						:alt="file.FileName"
					></VimeoThumbnail>
				</template>
			</PhotoGrid>
		</v-card-text>
		<v-card-actions>
			<v-chip>{{ offer.SGPOfferStatus.Name }}</v-chip>

			<template v-if="offer.StatusId === SgpOfferStatusIdEnum.SENT">
				<span class="ml-2 grey--text text-body-2">
					{{ offer.CreateDate | formatLocalDateTime }}</span
				>
			</template>
			<v-spacer></v-spacer>
			<v-btn
				v-if="offer.StatusId === SgpOfferStatusIdEnum.DRAFT"
				color="warning"
				text
				:disabled="sendingOffer"
				:loading="deletingOffer"
				@click="deleteOffer(offer.OfferId)"
				>{{ $t("button.discardDraft") }}</v-btn
			>
			<v-btn
				v-if="offer.StatusId === SgpOfferStatusIdEnum.DRAFT"
				@click="sendOffer(offer.OfferId)"
				:loading="sendingOffer"
				color="primary"
				>{{ $t("button.sendOffer") }}</v-btn
			>
		</v-card-actions>

		<v-dialog v-model="showQuickAccessLinkDialog" max-width="600">
			<v-card>
				<v-card-title
					><v-icon size="18" class="mr-3">fas fa-link</v-icon>Crea link per
					accesso</v-card-title
				>
				<v-card-text
					>Email: {{ generateLinkEmail }}
					<div class="my-3">{{ this.quickAccessLink }}</div>
				</v-card-text>

				<v-card-actions v-if="quickAccessLink === null">
					<v-spacer></v-spacer>
					<v-btn
						color="info"
						@click="CreateLink"
						:loading="loadingGenerateAccessLink"
						>Crea Link</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>
<script>
import Confirm from "@/components/Shared/Common/Confirm";
import UserPublicProfileName from "@/components/Shared/UI/UserPublicProfileName";
import CrudClient from "@/services/CrudClient/";

import PhotoGrid from "@/components/Shared/UI/PhotoGrid.vue";
import VimeoThumbnail from "@/components/Shared/UI/vimeoThumbnail.vue";
import OfferImage from "@/components/Shared/UI/OfferImage.vue";
import MailEventIcon from "../MailEventIcon.vue";
import { SgpOfferTypeEnum } from "./sgpOfferTypesEnum.js";
import { SgpOfferStatusIdEnum } from "./sgpOfferStatus";

import { mapActions, mapGetters } from "vuex";
import ApproveStatusIcon from "@/views/ThirdPartyApprover/ApproveStatusIcon.vue";

export default {
	name: "AdminOfferCard",
	components: {
		ApproveStatusIcon,
		Confirm,
		UserPublicProfileName,
		VimeoThumbnail,
		PhotoGrid,
		OfferImage,
		MailEventIcon
	},
	data() {
		return {
			deletingOffer: false,
			sendingOffer: false,
			showQuickAccessLinkDialog: false,
			generateLinkEmail: null,
			generateLinkOfferId: null,
			quickAccessLink: null,
			loadingGenerateAccessLink: false,
			ApproverUrl: null
		};
	},
	props: {
		offer: {
			type: Object,
			required: true
		}
	},
	computed: {
		...mapGetters(["SasToken"])
	},
	created() {
		this.OffersService = new CrudClient("SGP/Offers");
		this.SgpOfferTypeEnum = SgpOfferTypeEnum;
		this.SgpOfferStatusIdEnum = SgpOfferStatusIdEnum;
	},
	async mounted() {
		if (this.offer.EnableThirdPartyApproval) {
			this.ApproverUrl = await this.OffersService.Get(
				this.offer.OfferId,
				"ApproverUrl"
			);
		}
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async deleteOffer(offerId) {
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					this.$t("common.confirmDelete")
				))
			) {
				return;
			}

			try {
				this.deletingOffer = true;
				await this.OffersService.Delete(offerId);
				this.snackSuccess({ Text: this.$t("offerDeleted") });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotDeleteItem") });
			} finally {
				this.deletingOffer = false;
				this.$emit("offerDeleted");
			}
		},

		async sendOffer(offerId) {
			if (
				!(await this.$refs.confirm.open(
					this.$t("confirmSendTitle"),
					this.$t("confirmSendText")
				))
			) {
				return;
			}

			try {
				this.sendingOffer = true;
				await this.OffersService.Post(offerId, null, "sendoffer");

				this.snackSuccess({ Text: this.$t("offerSent") });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("cannotSendOffer") });
			} finally {
				this.sendingOffer = false;
				this.$emit("offerUpdated");
			}
		},

		ShowQuickAccessDialog(offerId, email) {
			this.showQuickAccessLinkDialog = true;
			this.generateLinkEmail = email;
			this.generateLinkOfferId = offerId;
			this.quickAccessLink = null;
		},

		CopyApproverUrl() {
			navigator.clipboard.writeText(this.ApproverUrl);
			this.snackSuccess({ Text: "Url copied!" });
		},

		async CreateLink() {
			let link;

			try {
				this.loadingGenerateAccessLink = true;
				const dto = { email: this.generateLinkEmail };
				link = await this.OffersService.Post(
					this.generateLinkOfferId,
					dto,
					"GenerateAccessToken",
					true
				);

				this.quickAccessLink = link;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("cannotGenerateLink") });
			} finally {
				this.loadingGenerateAccessLink = false;
			}

			try {
				await navigator.clipboard.writeText(link);

				this.snackSuccess({ Text: "Link copiato a clipboard!" });
			} catch (error) {
				this.$captureError(error);
				// this.snackError({ Text: this.$t("cannotGenerateLink") });
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.ArtistInfo {
	position: absolute;
	bottom: 5px;
	right: 5px;
	background-color: black;
	opacity: 0.5;
	padding: 2px 5px;
	border-radius: 4px;
	color: white;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 90%;
}
.ApprovalInfo {
	position: absolute;
	top: 5px;
	right: 5px;
	background-color: black;
	opacity: 0.8;
	padding: 2px 5px;
	border-radius: 4px;
	color: white;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 90%;
}
</style>
<i18n>
{"it":{
	"button.discardDraft": "Elimina bozza",
	"button.sendOffer": "Invia Proposta",
	"offerDeleted": "Proposta cancellata",
	"cannotSendOffer": "Errore durante l'invio  della proposta",
	"offerSent": "La proposta inviata!",
	"confirmSendTitle": "Confermo invio",
	"confirmSendText": "Sei sicuro di voler inviare la proposta?",
	"offerSent": "Proposta inviata con successo"
}}
</i18n>
