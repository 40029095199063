<template>
	<v-img
		:src="file.Metadata.PreviewFile + SasToken"
		:alt="file.DisplayName"
		:aspect-ratio="file.Metadata.AspectRatio"
		class="grey darken-4 rounded"
		@click="$emit('click')"
	>
		<div
			class="ArtistInfo"
			v-if="showArtist && file.Metadata && file.Metadata.Artist"
		>
			{{ file.Metadata.Artist }}
		</div>

		<div
			class="ImageDescription"
			v-if="
				showImageDescription && file.Metadata && file.Metadata.ImageDescription
			"
		>
			{{ file.Metadata.ImageDescription }}
		</div>

		<div class="FaceRecognitionIcon" v-if="showImageDescription && file.Metadata">
			<!-- Manuel edit case -->
			<v-icon
				color="success"
				v-if="file.Metadata['manuelExif'] && file.Metadata['manuelExif'] === 'true'"
				>fas fa-pen-square</v-icon
			>
			<template v-else>
				<!-- Not processed yet -->
				<v-icon
					color="warning lighten-1"
					v-if="file.Metadata.DetectedFaces === undefined"
					>fas fa-question-circle</v-icon
				>
				<!-- No faces detected -->
				<v-icon
					color="deep-purple darken-1"
					v-if="parseInt(file.Metadata.DetectedFaces) === 0"
					>fas fa-empty-set</v-icon
				>

				<!-- Faced found and identified -->
				<v-icon
					color="success"
					v-if="
						file.Metadata.DetectedFaces === file.Metadata.IdentifiedFaces &&
						file.Metadata.HasLowConfidenceResult !== 'true' &&
						parseInt(file.Metadata.IdentifiedFaces) > 0
					"
					>fas fa-check-double</v-icon
				>

				<!-- Faced found and identified with problems -->
				<span
					v-if="
						file.Metadata.DetectedFaces !== file.Metadata.IdentifiedFaces ||
						file.Metadata.HasLowConfidenceResult === 'true'
					"
				>
					<span class="infoText"
						>{{ file.Metadata.DetectedFaces }} /
						{{ file.Metadata.IdentifiedFaces }}</span
					>

					<v-icon color="warning">fas fa-exclamation-triangle</v-icon>
				</span>
			</template>
		</div>

		<template v-slot:placeholder>
			<v-row class="fill-height ma-0" align="center" justify="center">
				<v-progress-circular
					indeterminate
					color="grey lighten-2"
				></v-progress-circular>
			</v-row>
		</template>
	</v-img>
</template>
<style lang="scss" scoped>
.ArtistInfo {
	position: absolute;
	bottom: 5px;
	right: 5px;
	background-color: black;
	opacity: 0.5;
	padding: 2px 5px;
	border-radius: 4px;
	color: white;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 90%;
}

.ImageDescription {
	position: absolute;
	bottom: 5px;
	left: 5px;
	background-color: black;
	opacity: 0.5;
	padding: 2px 5px;
	border-radius: 4px;
	color: white;
	// white-space: nowrap;
	// overflow: hidden;
	// text-overflow: ellipsis;
	max-width: 90%;
}

.FaceRecognitionIcon {
	position: absolute;
	top: 5px;
	right: 5px;

	max-width: 90%;
	background-color: black;
	padding: 5px 5px;
	border-radius: 4px;
	opacity: 0.9;
}

.infoText {
	background-color: black;
	color: white;
	opacity: 0.5;
	padding: 2px 5px;
	border-radius: 4px;
	font-size: 16px;
}
</style>
<script>
import { mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters(["SasToken"]),
	},
	props: {
		file: {
			type: Object,
		},
		showArtist: {
			type: Boolean,
			default: false,
		},
		showImageDescription: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
