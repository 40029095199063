<template>
	<div @keydown.esc="DeselectImages">
		<confirm ref="confirm"></confirm>
		<vue-headful
			v-if="eventDetails"
			:title="eventDetails.Name"
			:description="$t('pageMetaDescription')"
		/>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="secondary--text text--lighten-2"
          >fas fa-caret-right</v-icon
        >
      </template>
		</v-breadcrumbs>

		<v-toolbar color="rounded" v-if="eventDetails">
			<v-toolbar-items v-if="selectedFilesCount">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon @click="SelectAll(false)" v-bind="attrs" v-on="on">
							<v-icon>fas fa-times-circle</v-icon>
						</v-btn>
					</template>
					<span>{{ $t("unselectFiles") }}</span>
				</v-tooltip>
			</v-toolbar-items>
			<v-toolbar-items v-else>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon @click="SelectAll(true)" v-bind="attrs" v-on="on">
							<v-icon>fas fa-check-circle</v-icon>
						</v-btn>
					</template>
					<span>{{ $t("selectAll") }}</span>
				</v-tooltip>
			</v-toolbar-items>
			<v-toolbar-title v-if="!selectedFilesCount"
				>{{ eventDetails.Name }}
			</v-toolbar-title>
			<v-toolbar-title v-else>
				{{ selectedFilesCount }}
				{{ selectedFilesCount == 1 ? $t("fileSelected") : $t("filesSelected") }}
			</v-toolbar-title>

			<v-spacer></v-spacer>
			<v-toolbar-items>
				<template v-if="!selectedFilesCount">
					<v-btn @click="TrainModel" color="info" :loading="TrainingData"
						>Train Model</v-btn
					>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon @click="pickFile" v-bind="attrs" v-on="on">
								<v-icon>fas fa-upload</v-icon>
							</v-btn>
						</template>
						<span>{{ $t("common.uploadTooltip") }}</span>
					</v-tooltip>

					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon @click="LoadStorageFolderFiles" v-bind="attrs" v-on="on">
								<v-icon>fas fa-sync-alt</v-icon>
							</v-btn>
						</template>
						<span>{{ $t("common.refreshData") }}</span>
					</v-tooltip>

					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								icon
								@click="SwitchView"
								:color="flatFilesView ? '' : 'info'"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>fas fa-folder-tree</v-icon>
							</v-btn>
						</template>
						<span>{{
							flatFilesView ? $t("enableFolderView") : $t("enableFlatFilesView")
						}}</span>
					</v-tooltip>
				</template>

				<template v-else>
					<v-btn @click="ShowExifUpdateDialog" color="info darken-2" tile>
						<v-icon left>fas fa-tag</v-icon> Caption
					</v-btn>

					<v-btn @click="ShowCopyrightUpdateDialog" color="info darken-1" tile>
						<v-icon left>fas fa-copyright</v-icon> Copyright
					</v-btn>

					<v-btn
						:loading="detectingFaces"
						@click="DetectFacesInSelected"
						color="info"
						tile
					>
						<v-icon left>fas fa-draw-square</v-icon> Trova Visi
					</v-btn>

					<v-btn
						@click="downloadSelected"
						:loading="preparingZip"
						color="info lighten-1"
						tile
					>
						<v-icon left>fas fa-file-archive</v-icon> Zip
					</v-btn>
					<v-btn icon @click="DeleteSelectedImages">
						<v-icon>fas fa-trash</v-icon>
					</v-btn>
				</template>
			</v-toolbar-items>

			<div slot="extension" v-if="!flatFilesView" class="">
				<FolderViewNavigation
					:Path="currentSubFolder"
					@navigate-to="navigateToSubFolder($event)"
				></FolderViewNavigation>
			</div>
		</v-toolbar>
		<div style="display: none">
			<v-file-input
				multiple
				@change="fileUploadSelected"
				v-model="uploadFiles"
				ref="inputFiles"
			></v-file-input>
		</div>
		<v-container>
			<v-row dense>
				<v-col cols="4">
					<!-- <v-switch
						v-model="processNewFiles"
						color="info"
						label="Trova visi subito"
					></v-switch
				> -->
			</v-col>
				<v-col cols="4">
					<v-select
						prepend-icon="fas fa-filter"
						label="Filtra"
						:items="filters"
						v-model="selectedFilter"
						@change="LoadStorageFolderFiles"
					>
					</v-select
				></v-col>
				<v-col class="text-right" v-if="zipFilesList && zipFilesList.length > 0"
					><span class="text-h6">Scarica Zip</span>
					<v-menu left v-model="menuDownloaded">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								@click="getZipFilesList"
								color="info"
								icon
								v-bind="attrs"
								v-on="on"
								><v-icon>fas fa-caret-down</v-icon></v-btn
							>
						</template>
						<v-list max-width="400px">
							<v-list-item v-for="zipBlob in zipFilesList" :key="zipBlob.Path">
								<v-list-item-content>
									<v-list-item-title>
										<span class="text-h6">{{
											zipBlob.DisplayName
										}}</span></v-list-item-title
									>

									<v-list-item-subtitle
										>{{ zipBlob.LastModified | formatLocalDateTime }} Dimensione:
										{{ zipBlob.FileSize | formatFileSize }}</v-list-item-subtitle
									>
								</v-list-item-content>

								<v-list-item-action>
									<v-btn icon :href="zipBlob.Uri + SasToken" target="_blank">
										<v-icon color="info">fas fa-download</v-icon>
									</v-btn>
								</v-list-item-action>
							</v-list-item>
						</v-list>
					</v-menu></v-col
				>
			</v-row></v-container
		>

		<v-progress-linear indeterminate v-if="LoadingImages"> </v-progress-linear>
		<v-container
			@drop.stop.prevent="dropFile"
			@dragover.prevent="dragOverHandler"
			@dragenter="setDragOverlay"
			@dragleave="setDragOverlay"
		>
			<v-overlay v-if="dragOverlay" :absolute="false" opacity="0.6">
				<!-- <v-progress-circular indeterminate size="64"></v-progress-circular> -->
				<v-icon size="120" color="grey">fas fa-upload</v-icon>
			</v-overlay>

			<SimpleFileUploadQueue
				:uploadQueue="uploadQueue"
				:currentSubFolder="currentSubFolder"
				:EventId="EventId"
				:processNewFiles="processNewFiles"
				@fileUploaded="OnFileUploaded"
				@fileUpdated="UpdateItems"
			></SimpleFileUploadQueue>
			<v-row>
				<v-col class="pa-1" v-if="!LoadingImages && Files.length === 0">
					<v-alert dark class="info"> Nessun file in evento </v-alert>
				</v-col>
			</v-row>

			<PhotoGrid :Files="Files" class="mt-5 mx-1" :targetHeight="300">
				<template v-slot:cell="{ file, index }">
					<v-responsive
						aspect-ratio="1.2"
						class="rounded elevation-3 pointer"
						v-if="file.IsDirectory"
						@click="setWorkingSubFolder(file)"
					>
						<v-row class="fill-height ma-0" align="center" justify="center">
							<div class="text-center">
								<v-icon size="60" color="yellow darken-2">fas fa-folder </v-icon>
								<br />

								{{ file.DisplayName }}
							</div>
						</v-row>
					</v-responsive>
					<template v-else>
						<SelectableItem
							v-if="file.Metadata"
							v-model="file.Selected"
							@selectClick="onItemSelected($event, index)"
							@unselectClick="onItemUnselected($event, index)"
							@zoomClick="ShowImageDialog(file, index)"
						>
							<!-- @click="" -->
							<AzurePreviewImage
								v-if="file.Metadata.PreviewFile"
								:file="file"
								class="darkened-image"
								:showImageDescription="true"
							></AzurePreviewImage>
						</SelectableItem>

						<v-responsive aspect-ratio="1.2" v-else>
							<v-row class="fill-height ma-0" align="center" justify="center">
								<div class="text-center">
									<v-icon size="60">fas fa-file-image </v-icon>
									<br />
									{{ file.DisplayName }}
								</div>
							</v-row>
						</v-responsive>
					</template>
				</template>
			</PhotoGrid>
		</v-container>

		<FaceRecoImageDialog
			v-model="showImageDialog"
			:EventId="EventId"
			:SgpEventsService="SgpEventsService"
			v-bind:carouselCurrentIndex="carouselCurrentIndex"
			@carousel-current-index-changed="OnCarouselIndexChanged"
			:Files="Files"
			@facesDetected="UpdateItems"
			@exifUpdated="ExifUpdated"
			@carouselRight="carouselMoveRight"
			@carouselLeft="carouselMoveLeft"
		></FaceRecoImageDialog>

		<UpdateExifDialog
			v-model="exifDialog.showUpdateExifDialog"
			@ExifUpdated="ExifUpdated"
			:EventId="EventId"
			:SelectedFiles="SelectedFiles"
		></UpdateExifDialog>
		<UpdateCopyrightDialog
			v-model="copyrightDialog.showUpdateCopyrightDialog"
			@ExifUpdated="ExifUpdated"
			:EventId="EventId"
			:SelectedFiles="SelectedFiles"
		></UpdateCopyrightDialog>
	</div>
</template>
<script>
import api from "@/services/api"
import CrudClient from "@/services/CrudClient/";
import { mapActions, mapGetters } from "vuex";

import FaceRecoImageDialog from "./FaceRecoImageDialog.vue";
import Confirm from "@/components/Shared/Common/Confirm";
import SelectableItem from "@/components/Shared/Common/SelectableItemV2.vue";
import AzurePreviewImage from "@/components/Shared/UI/AzurePreviewImage.vue";

import SimpleFileUploadQueue from "./components/SimpleFileUploadQueue.vue";

import FolderViewNavigation from "@/components/Shared/UI/FolderViewNavigation.vue";

import PhotoGrid from "@/components/Shared/UI/PhotoGrid.vue";
import UpdateExifDialog from "./components/UpdateExifDialog";
import UpdateCopyrightDialog from "./components/UpdateCopyrightDialog";

import { QueueStatus } from "./components/queueStatusEnum.js";
import { sleep, asyncPool } from "./AsyncPool.js";

export default {
	name: "FaceRecoEventDetails",
	components: {
		FaceRecoImageDialog,
		Confirm,
		SelectableItem,
		PhotoGrid,
		AzurePreviewImage,
		FolderViewNavigation,
		SimpleFileUploadQueue,
		UpdateExifDialog,
		UpdateCopyrightDialog,
	},
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
			eventDetails: null,
			LoadingImages: false,

			flatFilesView: false,
			currentSubFolder: null,
			Files: [],
			lastSelectedFileIndex: null,

			showImageDialog: false,

			uploadFiles: [], // binded to file-input control
			uploadQueue: [], // file-input files are added to the queue

			carouselCurrentIndex: null,

			dragOverlay: 0,
			showCreateOfferDialog: false,
			createOfferDialogMode: 1,
			showDirectDeliveryDialog: false,

			GridView: [],
			TrainingData: false,

			detectingFaces: false,
			processNewFiles: true,
			preparingZip: false,
			selectedFilter: 0,
			filters: [
				{
					text: "Tutte",
					value: 0,
				},
				{
					text: "Non elaborate",
					value: 1,
				},
				{
					text: "Visi trovati ma non riconosciuti",
					value: 2,
				},
				{
					text: "Visi trovati e riconosciuti",
					value: 3,
				},
				{
					text: "Nessun viso trovato",
					value: 4,
				},
			],
			loadingZipFilesList: false,
			zipFilesList: null,
			menuDownloaded: false,

			exifDialog: {
				showUpdateExifDialog: false,
			},

			copyrightDialog: { showUpdateCopyrightDialog: false },
		};
	},
	computed: {
		...mapGetters(["SasToken"]),
		EventId() {
			return parseInt(this.$route.params.EventId);
		},

		selectedFilesCount() {
			return this.SelectedFiles.length;
		},

		SelectedFiles() {
			return this.Files.filter((f) => f.Selected);
		},
	},
	created() {
		this.SgpEventsService = new CrudClient("SGP/Events");
		this.FacePersonService = new CrudClient("Face/Person");

		this.QueueStatus = QueueStatus;

		this.ParallelTasksCount = 8;
		this.DelayFirstBatchMs = 1000;
	},
	async mounted() {
		this.eventDetails = await this.SgpEventsService.Get(this.EventId);

		this.breadcrumbsItems.push({
			text: this.$t("sgp.recoEvents"),
			disabled: false,
			exact: true,
			to: "/RecoEvents",
		});
		this.breadcrumbsItems.push({
			text: this.eventDetails.Name,
			disabled: true,
			exact: true,
			to: "/RecoEvents/" + this.EventId,
		});

		await this.LoadStorageFolderFiles();
		await this.getZipFilesList();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		UpdateItems(updatedItems) {
			this.$log.debug("Received updatedItems");
			this.$log.debug(updatedItems);
			updatedItems.forEach((item) => {
				this.Files.find((f) => f.Path === item.Path).Metadata = item.Metadata;
			});
		},

		ExifUpdated(item) {
			this.Files.find((f) => f.Path === item.Path).Metadata = item.Metadata;
		},

		ShowExifUpdateDialog() {
			this.exifDialog.showUpdateExifDialog = true;
		},

		ShowCopyrightUpdateDialog() {
			this.copyrightDialog.showUpdateCopyrightDialog = true;
		},

		async TrainModel() {
			try {
				this.TrainingData = true;
				await this.FacePersonService.Post(null, null, "Train");

				this.snackSuccess({ Text: this.$t("Training Completed") });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.TrainingData = false;
			}
		},

		onItemSelected(event, index) {
			this.$log.debug("input: " + index);

			if (
				this.lastSelectedFileIndex != null &&
				this.lastSelectedFileIndex !== index
			) {
				if (event.shiftKey) {
					const start =
						this.lastSelectedFileIndex < index ? this.lastSelectedFileIndex : index;
					const end =
						this.lastSelectedFileIndex > index ? this.lastSelectedFileIndex : index;

					for (let i = start; i < end; i++) {
						this.Files[i].Selected = true;
					}
				}
			}
			this.lastSelectedFileIndex = index;
		},

		onItemUnselected() {
			this.lastSelectedFileIndex = null;
		},

		dragOverHandler(e) {
			e.dataTransfer.dropEffect = "move";
		},

		setDragOverlay(e) {
			if (e.type === "dragenter") {
				this.dragOverlay++;
			}
			if (e.type === "dragleave") {
				this.dragOverlay--;
			}
		},

		async dropFile(e) {
			this.dragOverlay = 0;

			const droppedFiles = [...e.dataTransfer.files];
			if (!droppedFiles) return;

			droppedFiles.forEach((element) => {
				element.uploadStatus = this.QueueStatus.QUEUED;

				this.uploadQueue.push(element);
			});
		},

		async OnFileUploaded(file) {
			this.Files.push(file);
		},

		ShowImageDialog(file, index) {
			this.$log.debug(file);
			this.$log.debug(index);

			this.showImageDialog = true;
			this.carouselCurrentIndex = index;
		},

		async SwitchView() {
			if (this.LoadingImages) return;
			this.flatFilesView = !this.flatFilesView;
			this.currentSubFolder = null;

			await this.LoadStorageFolderFiles();
		},

		async navigateToSubFolder(path) {
			if (this.LoadingImages) return;
			this.currentSubFolder = path;

			await this.LoadStorageFolderFiles();
		},

		async setWorkingSubFolder(file) {
			if (this.LoadingImages) return;
			if (file) {
				if (!this.currentSubFolder) this.currentSubFolder = "";

				this.currentSubFolder += file.DisplayName + "/";
			} else {
				this.currentSubFolder = null;
			}

			await this.LoadStorageFolderFiles();
		},

		carouselMoveRight() {
			if (this.carouselCurrentIndex < this.Files.length - 1) {
				this.carouselCurrentIndex++;
			}
		},
		carouselMoveLeft() {
			if (this.carouselCurrentIndex > 0) this.carouselCurrentIndex--;
		},

		async LoadStorageFolderFiles() {
			this.LoadingImages = true;
			try {
				let parameters = "flatView=" + this.flatFilesView;
				if (this.currentSubFolder) {
					parameters += "&subFolder=" + encodeURIComponent(this.currentSubFolder);
				}

				const orderBy = "FileName";
				parameters += "&orderBy=" + orderBy;

				const res = await this.SgpEventsService.Get(
					this.EventId,
					"Files?" + parameters
				);

				this.lastSelectedFileIndex = null;
				// add Selected flag to files
				res.forEach((f) => (f.Selected = false));

				switch (this.selectedFilter) {
					case 0: // All
						this.Files = res;
						break;
					case 1: // Not processed
						this.Files = res.filter(
							(f) => f.IsDirectory || f.Metadata.DetectedFaces === undefined
						);
						break;

					case 2:
					case 3:
					case 4:
						this.Files = res.filter(
							(f) =>
								f.IsDirectory ||
								this.filterFilesByIdentificationStatus(this.selectedFilter, f.Metadata)
						);
						break;
				}
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingImages = false;
			}
		},

		filterFilesByIdentificationStatus(filterId, metadata) {
			if (
				metadata.DetectedFaces === undefined ||
				metadata.DetectedFaces === null ||
				metadata.DetectedFaces === ""
			) {
				return false;
			}

			const detectedFaceCount = parseInt(metadata.DetectedFaces);
			const identifiedFaceCount = parseInt(metadata.IdentifiedFaces);
			const hasLowConfidenceResult =
				metadata.manuelExif && metadata.HasLowConfidenceResult === "true";

			const manuelExif = metadata.manuelExif && metadata.manuelExif === "true";

			switch (filterId) {
				case 2: // Detected, identified with problems
					return (
						!manuelExif &&
						!(detectedFaceCount === identifiedFaceCount && !hasLowConfidenceResult)
					);
				case 3: // Detected, identified
					return (
						manuelExif ||
						(detectedFaceCount > 0 &&
							detectedFaceCount === identifiedFaceCount &&
							!hasLowConfidenceResult)
					);

				case 4: // 0 faces detected
					return detectedFaceCount === 0;
			}
		},

		SelectAll(val) {
			this.Files.filter((f) => !f.IsDirectory).forEach((f) => (f.Selected = val));
		},

		async DeleteSelectedImages() {
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					this.$t("common.confirmDelete")
				))
			) {
				return;
			}

			const selectedFiles = this.SelectedFiles.map((f) => f.Path);

			this.LoadingImages = true;
			try {
				await this.SgpEventsService.Post(
					this.EventId,
					selectedFiles,
					"DeleteFiles",
					false
				);

				// reactively remove selected images
				selectedFiles.forEach((selectedFile) => {
					this.Files.splice(
						this.Files.findIndex((f) => f.Path === selectedFile),
						1
					);
				});
			} catch (error) {
				this.snackError("common.error.cannotDelete");
				this.$captureError(error);
			} finally {
				this.LoadingImages = false;
			}
		},

		async DetectSingleFile(file, index) {
			const azureFunctionClient = new CrudClient("Face", api.azureFunctionApiUrl);
			try {
				if (index < this.ParallelTasksCount) {
					await sleep(index * this.DelayFirstBatchMs);
				}

				var identifyResult = await azureFunctionClient.Post(
					this.EventId,
					{ ImageFilePath: file },
					"IdentifyFaces",
					true
				);
				this.UpdateItems([identifyResult]);
				return identifyResult;
			} catch (error) {
				this.$captureError(error);
				return {
					error: error,
					file: file
				};
			}
		},

		async DetectFaces(selectedFiles) {
			var processedItems = await asyncPool(this.ParallelTasksCount, selectedFiles, (file, i) =>
				this.DetectSingleFile(file, i)
			);

			// this.$log.debug(processedItems);

			const successfulItems = processedItems.filter(i => i.error === undefined);
			// this.$log.debug(successfulItems);
			if (successfulItems.length < processedItems.length) {
				this.snackError({
					Title: "Operazione completato con alcuni errori",
					Text: successfulItems.length + " elaborati con successo, " +
						(processedItems.length - successfulItems.length) + " errori"
				});
			}
		},

		async DetectFacesInSelected() {
			const selectedFiles = this.SelectedFiles.map((f) => f.Path);
			this.detectingFaces = true;

			await this.DetectFaces(selectedFiles);
			this.detectingFaces = false;
		},

		async downloadSelected() {
			const selectedFiles = this.SelectedFiles.map((f) => f.Path);

			try {
				this.preparingZip = true;
				const azureFunctionClient = new CrudClient("Face", api.azureFunctionApiUrl);
				await azureFunctionClient.Post(this.EventId, selectedFiles, "ZipFiles");

				this.snackSuccess({ Text: this.$t("Cartella Zip è pronta ") });
				this.menuDownloaded = true;
				await this.getZipFilesList();
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotDownload") });
				this.$captureError(error);
			} finally {
				this.preparingZip = false;
			}
		},

		async getZipFilesList() {
			try {
				this.loadingZipFilesList = true;
				const azureFunctionClient = new CrudClient("Face", api.azureFunctionApiUrl);

				this.zipFilesList = (
					await azureFunctionClient.Get(this.EventId, "ZipFiles")
				).sort((a, b) => a.LastModified < b.LastModified);
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.loadingZipFilesList = false;
			}
		},

		pickFile() {
			this.$refs.inputFiles.$refs.input.click();
		},

		async fileUploadSelected() {
			this.$log.debug(this.uploadFiles);
			for (const file of this.uploadFiles) {
				file.url = window.URL.createObjectURL(file);
				file.uploadStatus = this.QueueStatus.QUEUED;
				this.uploadQueue.push(file);
			}
			this.uploadFiles = [];

			// await this.UploadFilesFromQueue();
			// this.$log.debug("UploadFilesFromQueue ended");
		},

		OnCarouselIndexChanged(index) {
			this.carouselCurrentIndex = index;
		},
	},
};
</script>

<style lang="scss" scoped>
.darkened-image {
	filter: brightness(100%);
	transition: 0.3s;
	&:hover {
		filter: brightness(80%);
	}
}
</style>
<i18n>
{
	"it":{
		"fileSelected": "file selezionato",
		"filesSelected": "file selezionati",
		"unselectFiles": "Deseleziona i file",
		"selectAll": "Seleziona tutti i file",
		"enableFolderView":"Abilita vista cartelle",
		"enableFlatFilesView":"Visualizza tutte le foto"
	}
}
</i18n>
