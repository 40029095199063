<template>
	<div>
		<vue-headful
			v-if="order"
			:title="$t('sgp.order') + ' ' + order.OrderId"
			:description="$t('pageMetaDescription')"
		/>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
			<v-icon class="secondary--text text--lighten-2"
				>fas fa-caret-right</v-icon
			>
      </template>
		</v-breadcrumbs>

		<v-progress-linear indeterminate v-if="loadingOrders"></v-progress-linear>
		<AdminOrderCard
			v-if="order"
			:order="order"
			@updated="LoadOrders"
		></AdminOrderCard>
	</div>
</template>
<script>
import AdminOrderCard from "./components/AdminOrderCard.vue";
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";

export default {
	components: {
		AdminOrderCard,
	},
	data() {
		return {
			order: null,
			loadingOrders: false,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
		};
	},
	computed: {
		OrderId() {
			return parseInt(this.$route.params.OrderId);
		},
	},
	created() {
		this.SgpOrdersService = new CrudClient("SGP/Orders");
	},
	async mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("sgp.orders"),
			disabled: false,
			exact: true,
			to: "/Orders",
		});

		this.breadcrumbsItems.push({
			text: this.OrderId,
			disabled: true,
			exact: true,
			to: "/Orders/" + this.OrderId,
		});

		await this.LoadOrders();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadOrders() {
			try {
				this.loadingOrders = true;
				this.order = await this.SgpOrdersService.GetSelectedFields(
					this.OrderId,
					`*,
					SGPEvent.*,
					SGPOffer.Title, SGPOffer.OfferType,
					SGPOrderItems.*, SGPOrderItems.PhotographyLicense.Name,
					SGPOrderStatus.*, Skill.Name, SGPOrderTransactions.*`
				);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.loadingOrders = false;
			}
		},
	},
};
</script>
