<template>
	<v-dialog
		fullscreen
		persistent
		:value="value"
		@input="$emit('input', $event)"
		@keydown.esc="closeDialog"
	>
		<v-card>
			<v-toolbar>
				<v-toolbar-title>{{ $t("sgp.directDeliveryOrder") }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn icon @click="closeDialog"><v-icon>fas fa-times</v-icon></v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-card-text>
				<v-overlay :absolute="true" :value="SendingOrder">
					<v-progress-circular indeterminate size="64"></v-progress-circular>
				</v-overlay>
				<form>
					<AccountPicker
						v-model="ClientUserId"
						data-vv-name="ClientUserId"
						:data-vv-as="$t('ClientUserId')"
						data-vv-delay="300"
						v-validate="'required'"
						:error-messages="errors.collect('ClientUserId')"
					></AccountPicker>
					<v-text-field
						:label="$t('Total')"
						type="number"
						v-model="Total"
						data-vv-name="Total"
						:data-vv-as="$t('Total')"
						data-vv-delay="300"
						v-validate="'required'"
						:error-messages="errors.collect('Total')"
					></v-text-field>
					<v-select
						:items="Licenses"
						item-value="LicenseId"
						item-text="Name"
						:label="$t('sgp.license')"
						v-model="LicenseId"
						data-vv-name="LicenseId"
						:data-vv-as="$t('LicenseId')"
						data-vv-delay="300"
						v-validate="'required'"
						:error-messages="errors.collect('LicenseId')"
					></v-select>
				</form>
				<!-- v-if added for calculating grid layout otherwise it's raw images -->
				<PhotoGrid
					:Files="SelectedFiles"
					class="mt-5"
					:targetHeight="200"
					ref="photoGrid"
				>
					<template v-slot:cell="{ file }">
						<AzurePreviewImage
							v-if="file.Metadata.PreviewFile"
							:file="file"
						></AzurePreviewImage>
						<VimeoThumbnail
							v-else-if="file.Metadata.type === 'video'"
							:src="file.Metadata.thumbnail_url"
							:alt="file.Metadata.title"
						></VimeoThumbnail>
					</template>
				</PhotoGrid>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="CreateDirectDeliveryOrder">{{
					$t("common.send")
				}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";
import VimeoThumbnail from "@/components/Shared/UI/vimeoThumbnail.vue";
import AzurePreviewImage from "@/components/Shared/UI/AzurePreviewImage.vue";
import PhotoGrid from "@/components/Shared/UI/PhotoGrid.vue";
import AccountPicker from "@/components/Account/ClientAccountPicker";

export default {
	name: "CreateDirectDeliveryOrder",
	$_veeValidate: { validator: "CreateDirectDeliveryOrder" },
	components: { VimeoThumbnail, PhotoGrid, AzurePreviewImage, AccountPicker },
	data() {
		return {
			LoadingLicenses: false,
			Licenses: [],
			Total: 0,
			LicenseId: null,
			ClientUserId: null,
			SendingOrder: false,
		};
	},
	props: {
		value: { type: Boolean, required: true },
		SelectedFiles: { type: Array, required: true },
		EventId: { type: Number, required: true },
	},

	watch: {
		value: {
			immediate: true,
			handler: async function (value) {
				if (value) {
					// because of the animation effects, nextTick does not work here
					setTimeout(() => {
						if (this.$refs.photoGrid) this.$refs.photoGrid.calculateGridView();
					}, 400);
				}
			},
		},
	},
	created() {
		this.SgpOrdersService = new CrudClient("SGP/Orders");
		this.PhotographyLicensesService = new CrudClient("SGP/PhotographyLicenses");
		this.SgpClientDefaultPricesService = new CrudClient(
			"SGP/ClientDefaultPrices"
		);
	},
	async mounted() {
		await this.LoadLicenses();
	},

	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadLicenses() {
			try {
				this.LoadingLicenses = true;

				const res = await this.PhotographyLicensesService.GetPaged({
					skip: 0,
					limit: 0,
					fields: "LicenseId, Name, DefaultPrice, MediaType.*",
					filter: "IsActive:true",
				});

				this.Licenses = res.Data;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingLicenses = false;
			}
		},

		async CreateDirectDeliveryOrder() {
			const result = await this.$validator.validate();

			if (!result) return;

			this.SendingOrder = true;
			try {
				const dto = {
					ClientUserId: this.ClientUserId,
					Total: this.Total,
					EventId: this.EventId,
				};

				// create draft order
				const res = await this.SgpOrdersService.Post(
					null,
					dto,
					"DirectDelivery",
					true
				);

				// add order items
				for (let i = 0; i < this.SelectedFiles.length; i++) {
					const itemDto = {
						Path: this.SelectedFiles[i].Path,
						MediaTypeId: 1,
						LicenseId: this.LicenseId,
						Price: 0,
					};

					const orderItem = await this.SgpOrdersService.Post(
						res.OrderId,
						itemDto,
						"ordermedia",
						true
					);

					this.$log.debug(orderItem);
				}

				// deliver the order
				await this.SgpOrdersService.Post(res.OrderId, null, "send", false);

				this.snackSuccess({ Text: this.$t("orderCreated") });

				this.$emit("input", false);

				// create order zip without waiting...
				this.SgpOrdersService.Post(res.OrderId, null, "zip", false);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("error.cannotSaveData") });
			} finally {
				this.SendingOrder = false;
			}
		},

		closeDialog() {
			this.$emit("input", false);
		},
	},
};
</script>
<i18n>
{
	"it":{
		"Total": "Totale",
		"orderCreated": "Ordine crato! Le mail di notifica inviate"
	}
}
</i18n>
